import { IRule, IRules } from "../models/external-exam-prescription";
import { GlobalAxios } from "./../axios/axios-config";

const PRICE_RULE_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/ForuBusRuleRequestExtPresProc";

export const createPrescriptionGenerationRule = (item: IRule) => {
  return GlobalAxios.post(PRICE_RULE_ROUTE, item);
};

export const getPrescriptionGenerationRuleById = (id: string) => {
  return GlobalAxios.get<IRules>(PRICE_RULE_ROUTE + "/" + id);
};

export const updatePrescriptionGenerationRuleById = ({
  item,
  id,
}: {
  item: IRule;
  id: string;
}) => {
  return GlobalAxios.put(PRICE_RULE_ROUTE + "/" + id, item);
};
