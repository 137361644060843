import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import MainApiService from "../../../../../services/mainApi.service";

import { Table } from "@4uhub/lib4uhub";
import { Chip } from "@mui/material";
import { IHealthChecks } from "../../../../../models/health-checks";

const HEALTH_CHECK_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/HealthCheck/";

const healthCheckService = new MainApiService<IHealthChecks>(
  HEALTH_CHECK_ROUTE
);

const translationPath = "page.settings.generalSettings.healthChecks.";

const HealthChecksPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { eI } = useParams();

  const columns: GridColDef<IHealthChecks>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: t(translationPath + "name") || "Name",
      },
      {
        field: "establishment",
        headerName: t(translationPath + "establishment") || "Establishment",
        renderCell: (params) => {
          return params.value ? params.value.name : "-";
        },
      },
      {
        field: "enable",
        headerName: t(translationPath + "status") || "Status",
        maxWidth: 120,
        renderCell: (params) => {
          return (
            <Chip
              label={
                params.value
                  ? t(translationPath + "active")
                  : t(translationPath + "inactive")
              }
              color={params.value ? "success" : "error"}
              size="small"
            />
          );
        },
      },
    ],
    [t]
  );

  const handleRowClick = (params: GridRowParams<IHealthChecks>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<IHealthChecks>
      showDefaultMacroFilters={false}
      service={healthCheckService}
      columns={columns}
      title={t(translationPath + "health_checks") || "Health Checks"}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      backButton
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a health check"
      }
      addButtonLabel={
        t(translationPath + "add_health_check") || "Add Health Check"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default HealthChecksPage;
