import { Stack, Typography } from "@mui/material";
import { memo } from "react";
import FolderOffIcon from "@mui/icons-material/FolderOff";

export const NoRows = memo(() => {
  return (
    <Stack alignItems={"center"} justifyContent={"center"} height={"100%"}>
      <FolderOffIcon
        sx={(theme) => ({
          fontSize: 40,
          mb: 1,
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[600]
              : theme.palette.grey[400],
        })}
      />
      <Typography>Nenhum resultado encontrado</Typography>
    </Stack>
  );
});
