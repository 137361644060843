import { useCallback, useEffect, useState } from "react";
import { useFetch } from "@4uhub/lib4uhub";
import { Box, CircularProgress } from "@mui/material";

import NoData from "../../../../components/Logs/NoData/NoData";
import PrettyJson from "../../../admSystem/establishments/OnPremise/PrettyJson";
import { listIpmTasyFull } from "../../../../services/tasyIpmLog.service";
import { IIpmTasyLog } from "../../../../models/ipm-tasy-logs";

interface IIpmTasyModalProps {
  id: number;
}

const IpmTasyModal = ({ id }: IIpmTasyModalProps) => {
  const [content, setContent] = useState<IIpmTasyLog>();

  const { sendRequest, loading } = useFetch(listIpmTasyFull);

  const fetchData = useCallback(async () => {
    const { data, success } = await sendRequest(id);
    if (data && success) {
      setContent(data);
    }
  }, [sendRequest, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return (
      <Box
        minWidth={100}
        minHeight={100}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!content) {
    return <NoData message="Nenhum registro encontrado" />;
  }

  return <PrettyJson>{content}</PrettyJson>;
};

export default IpmTasyModal;
