import { z } from "zod";

const translationPath = "page.settings.generalSettings.healthChecks.errors.";

const establishmentSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

export const healthCheckSchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  name: z.string().min(1, translationPath + "name"),
  uri: z.string().min(1, translationPath + "uri"),
  payload: z.string().min(1, translationPath + "payload"),
  restoredPayload: z.string().min(1, translationPath + "restored_payload"),
  webhookURL: z
    .string()
    .min(1, translationPath + "webhook_url")
    .refine(
      (val) => val.startsWith("http://") || val.startsWith("https://"),
      translationPath + "webhook_url_http"
    ),
  establishment: establishmentSchema.optional().nullable(),
});

export type THealthCheckForm = z.infer<typeof healthCheckSchema>;
