import { Box, Stack, Typography } from "@mui/material";
import { RunSqlCode } from "../../../../../components/ExecuteSql/RunSqlCode/RunSqlCode";
import { useCallback, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { BackButton, OverflowTooltip } from "@4uhub/lib4uhub";
import { SqlTable } from "../../../../../components/ExecuteSql/SqlTable/SqlTable";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const translationPath = "page.settings.system.executeSql.";

const SettingsExecuteSql = () => {
  const { t } = useTranslation();

  const { eI } = useParams();

  const [sqlReturn, setSqlReturn] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const getSqlReturnHandler = useCallback((data: any[]) => {
    setSqlReturn(data);
  }, []);

  const columns: GridColDef<any>[] =
    sqlReturn.length > 0
      ? Object.keys(sqlReturn[0]).map((key) => ({
          field: key,
          headerName: key,
          flex: 1,
          minWidth: 150,
          renderCell: (params) => {
            const value =
              typeof params.value === "object"
                ? JSON.stringify(params.value)
                : params.value;

            return (
              <OverflowTooltip
                text={value}
                tooltip={value}
                textTransform="none"
                variant="body2"
              />
            );
          },
        }))
      : [];

  return (
    <Box
      sx={{
        height: "100%",
        pt: 4,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
        }}
      >
        <BackButton url={`/e/${eI}/dashboard/settings`} />
        <Typography variant="h5">
          {t(translationPath + "execute_sql")}
        </Typography>
      </Stack>
      <RunSqlCode
        onGetSqlReturn={getSqlReturnHandler}
        onLoading={setIsLoading}
      />
      <SqlTable columns={columns} sqlReturn={sqlReturn} isLoading={isLoading} />
    </Box>
  );
};

export default SettingsExecuteSql;
