import { memo, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useFormContext } from "@4uhub/lib4uhub";

import { TBannerForm } from "../BannerSchema";
import ImagePreview from "./ImagePreview";
import { EBannerLocale } from "../BannersLocals/RecommendedSize";

const BannersPreview = () => {
  const { control } = useFormContext<TBannerForm>();

  const { bannerLocals, bannerMediaFiles, siteApps } = useWatch<TBannerForm>({
    control: control,
  });

  const locales = useMemo(
    () =>
      bannerLocals
        ? bannerLocals
            .map((b) => b.code!)
            .filter(
              (b) =>
                b !== EBannerLocale.LOGIN_IN_SYSTEM &&
                b !== EBannerLocale.OPEN_SITE_APP
            )
        : [],
    [bannerLocals]
  );

  const file = useMemo(
    () =>
      bannerMediaFiles &&
      bannerMediaFiles.length > 0 &&
      bannerMediaFiles[0].file,
    [bannerMediaFiles]
  );

  let src = useMemo(() => {
    if (!file) return null;

    if (file.cdnDomain && file.filePath) {
      return file.cdnDomain + "/" + file.filePath;
    }

    if (file.file) {
      return URL.createObjectURL(file.file);
    }
  }, [file]);


  if (
    locales.length === 0 ||
    !file ||
    !siteApps ||
    siteApps.length === 0 ||
    !src
  ) {
    return null;
  }

  const showMobile =
    !!siteApps &&
    siteApps.some(
      (a) => a.siteAppType?.code === "2" || a.siteAppType?.code === "4"
    );

  const showDesktop =
    !!siteApps && siteApps.some((a) => a.siteAppType?.code === "1");
  

  return (
    <ImagePreview
      src={src}
      locales={locales}
      key={file.id}
      showDesktop={showDesktop}
      showMobile={showMobile}
    />
  );
};

export default memo(BannersPreview);
