import { GlobalAxios } from "../axios/axios-config";
import { IExamSendRule, INewExamSendRule } from "../models/exam-send-rule";

const EXAM_SEND_RULE_ROUTE =
  process.env.REACT_APP_4UBUS +
  "/api/v1/ExtensionEstablismentTasySendZscanEvo/";

export const createExamSendRule = (item: INewExamSendRule) => {
  return GlobalAxios.post(EXAM_SEND_RULE_ROUTE, item);
};

export const getExamSendRuleById = (id: string) => {
  return GlobalAxios.get<IExamSendRule>(EXAM_SEND_RULE_ROUTE + id);
};

export const updateExamSendRuleById = ({
  item,
  id,
}: {
  item: INewExamSendRule;
  id: string;
}) => {
  return GlobalAxios.put(EXAM_SEND_RULE_ROUTE + id, item);
};
