import { Box, Stack, SxProps, Theme, Typography } from "@mui/material";
import React, { memo } from "react";

interface ILegendsProps {
  color?: string;
  label: string;
}

const Legend: React.FC<ILegendsProps> = ({ color, label }) => {
  const style: SxProps<Theme> = (t) => ({
    p: 1,
    ...t.typography.caption,
    borderRadius: 1,
    color: t.palette.common.black,
  });

  return (
    <Stack alignItems={"center"} flexDirection={"row"} gap={2}>
      <Box
        sx={(t) => ({
          borderRadius: 10,
          backgroundColor: color,
          height: 10,
          width: 10,
        })}
      />
      <Typography sx={style}>{label}</Typography>
    </Stack>
  );
};

export default memo(Legend);
