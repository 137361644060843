import { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";

const useFilter = <T extends Object>(filterParams: T) => {
  let initialState = useRef(filterParams);

  const [previousFilter, setPreviousFilter] = useState(filterParams);

  const [filter, setFilter] = useState<T>(filterParams);

  const disableFilterBtn =
    _.isEqual(filter, previousFilter) ||
    !filter ||
    _.isEqual(filter, initialState.current);

  const showCleanBtn = !_.isEqual(filterParams, initialState.current);

  const cleanFilter = useCallback(() => {
    setFilter(initialState.current);
    setPreviousFilter(initialState.current);
  }, []);

  const onSetFilter = useCallback((params: Partial<T>) => {
    setFilter((p) => {
      const newFilter = { ...p, ...params };
      return newFilter;
    });
  }, []);

  useEffect(() => {
    setPreviousFilter(filterParams);
  }, [filterParams]);

  return {
    disableFilterBtn,
    filter,
    showCleanBtn,
    setFilter: onSetFilter,
    cleanFilter,
    initialState,
  };
};

export default useFilter;
