import { Stack, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Dispatch, SetStateAction, memo } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTranslation } from "react-i18next";

import FiltersActions from "../../../../components/FiltersActions/FiltersActions";
import useFilter from "../../../../hooks/useFilter";

interface IMacroFilterProps {
  onFilterParams: Dispatch<SetStateAction<IFilterParams>>;
  filterParams: IFilterParams;
  onRefresh: () => void;
}

export interface IFilterParams {
  Status: string;
  PatientEncounterNumber: string;
  Date: Date;
  refresh?: Boolean;
}

const translationPath = "page.4uBus.logs.tasyXIpm.";

const MacroFilter: React.FC<IMacroFilterProps> = ({
  onFilterParams,
  filterParams,
  onRefresh,
}) => {
  const {
    disableFilterBtn,
    filter,
    showCleanBtn,
    initialState,
    setFilter,
    cleanFilter,
  } = useFilter(filterParams);

  const { t } = useTranslation();

  const filterHandler = () => {
    onFilterParams(filter);
  };

  const cleanHandler = () => {
    onFilterParams(initialState.current);
    cleanFilter();
  };

  return (
    <Stack flexDirection={"row"} gap={2}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={filter.Date}
          slotProps={{
            textField: {
              size: "small",
              fullWidth: true,
            },
            field: {
              clearable: false,
            },
          }}
          onChange={(v) => {
            if (v) {
              setFilter({ Date: new Date(v) });
            }
          }}
          format={"dd/MM/yyyy"}
        />
      </LocalizationProvider>

      <TextField
        value={filter.Status}
        size="small"
        label={t(translationPath + "search")}
        fullWidth
        onChange={(e) => setFilter({ Status: e.target.value })}
      />

      <TextField
        value={filter.PatientEncounterNumber}
        size="small"
        type="number"
        label={t(translationPath + "service_number_search")}
        fullWidth
        onChange={(e) => setFilter({ PatientEncounterNumber: e.target.value })}
      />

      <FiltersActions
        onFilter={filterHandler}
        onClean={cleanHandler}
        onRefresh={onRefresh}
        disableFilterBtn={disableFilterBtn}
        showCleanBtn={showCleanBtn}
      />
    </Stack>
  );
};
export default memo(MacroFilter);
