import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  DateInput,
  FormContainer,
  FormInput,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import { useCallback } from "react";
import {
  createExamSendRule,
  getExamSendRuleById,
  updateExamSendRuleById,
} from "../../../../../../../services/examSendRule.service";
import { examSendRuleSchema, TExamSendRuleForm } from "./examSendRuleSchema";

const translationPath = "page.settings.4uBus.zScan.examSendRule.";

const ExamSendRule = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(createExamSendRule);
  const { sendRequest: update, loading: uLoading } = useFetch(
    updateExamSendRuleById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(getExamSendRuleById);

  const updateHandler = (v: TExamSendRuleForm, id: string) =>
    update({
      item: {
        ...v,
        initialDate: v.initialDate.toISOString(),
        endDate: v.endDate.toISOString(),
      },
      id: id,
    });

  const saveHandler = (v: TExamSendRuleForm) =>
    save({
      ...v,
      initialDate: v.initialDate.toISOString(),
      endDate: v.endDate.toISOString(),
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TExamSendRuleForm>> => {
      const { data, success } = await get(id);
      if (data) {
        return {
          data: {
            ...data,
            initialDate: new Date(data.initialDate),
            endDate: new Date(data.endDate),
          },
          success: true,
        };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TExamSendRuleForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "exam_send_rule")}
      schema={examSendRuleSchema}
      subtitle={t(translationPath + "new_exam_send_rule")}
      subtitleWatchField={"targetMachine"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign="end">
            <SwitchInput
              name="enable"
              label={t(translationPath + "enable") || "Enable"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="targetMachine"
              fullWidth
              label={t(translationPath + "target_machine") || "Target Machine"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="procClassificationIntegrationId"
              fullWidth
              label={
                t(translationPath + "proc_classification_integration_id") ||
                "Proc Classification Integration Id"
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DateInput
              name="initialDate"
              label={t(translationPath + "initial_date") || "Initial Date"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DateInput
              name="endDate"
              label={t(translationPath + "end_date") || "End Date"}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default ExamSendRule;
