import { memo } from "react";
import { Link, Tooltip } from "@mui/material";

import { ITicket } from "./model";
import useCrmLink from "../../../hooks/useCrmLink";

interface ITicketProps {
  data: ITicket;
}
const Ticket: React.FC<ITicketProps> = ({ data }) => {
  const { crmClickHandler } = useCrmLink();

  const onClickHandler = () => {
    const redirect = `/w/${data.ticketChannelStep.ticketChannel.ticketWorkspace.id}/boards/${data.ticketChannelStep.ticketChannel.id}/card/${data.id}`;
    crmClickHandler(redirect);
  };

  return (
    <Tooltip title={data.title}>
      <Link
        component="button"
        variant="body2"
        onClick={onClickHandler}
        textOverflow={"ellipsis"}
        overflow={`hidden`}
      >
        {data.title}
      </Link>
    </Tooltip>
  );
};

export default memo(Ticket);
