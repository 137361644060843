import React, { Fragment, memo } from "react";
import { Controller } from "react-hook-form";
import { Loading, useFormContext } from "@4uhub/lib4uhub";
import { Divider, Stack } from "@mui/material";

import { IAvaiableSchedule } from "../../../../model";
import ScheduleItem from "./ScheduleItem";
import FormErrorMessage from "../../../../../../components/FormErrorMessage";

export interface ISelectHour {
  id: string;
  hour: string;
}

interface ISchedulesListProps {
  schedules: IAvaiableSchedule[];
  name: string;
  loading?: boolean;
}

export const SchedulesList: React.FC<ISchedulesListProps> = memo(
  ({ schedules, name, loading }) => {
    const { control } = useFormContext();

    if (loading) {
      return (
        <Stack alignItems={"center"} justifyContent={"center"} height={"100%"}>
          <Loading />
        </Stack>
      );
    }

    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <Stack>
              <Stack gap={2}>
                {schedules.map((s, i) => (
                  <Fragment key={s.identifier}>
                    <ScheduleItem
                      key={s.identifier}
                      data={s}
                      selectedHour={value}
                      onSelectedHour={onChange}
                    />
                    {i !== schedules.length - 1 && <Divider />}
                  </Fragment>
                ))}
              </Stack>
              {error && error.message && (
                <FormErrorMessage message={error.message} />
              )}
            </Stack>
          );
        }}
      />
    );
  }
);
