import { FilePicker, FormContainer, useFetch } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { addImport } from "../../../../../services/individualImports.service";
import { DownloadModel } from "../DownloadModel/DownloadModel";
import { importSchema, TImportForm } from "./importSchema";

const translationPath = "page.register.individualsImports.";

const Import = () => {
  const { t } = useTranslation();

  const { sendRequest, loading } = useFetch(addImport);

  const saveHandler = (v: TImportForm) =>
    sendRequest({
      ...v,
      file: v.file,
    });

  return (
    <FormContainer<TImportForm>
      title={t(`${translationPath}import`)}
      saveFunction={saveHandler}
      loading={loading}
      schema={importSchema}
      subtitle={t(`${translationPath}new_import`)}
      subtitleWatchField={"file.fileName"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <DownloadModel />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FilePicker
              name="file"
              label="Selecione um arquivo"
              accept="csv"
              maxSize={5}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Import;
