import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import {
  Modal,
  OverflowTooltip,
  TableNoPagination,
  UsePrivileges,
} from "@4uhub/lib4uhub";
import { format } from "date-fns";
import { ptBR, enUS, es } from "date-fns/locale";
import TocIcon from "@mui/icons-material/Toc";

import { NoPaginationApi } from "../../../../services/noPaginationApi.service";
import { ITasyIpmLogs } from "../../../../models/tasy-ipm-logs";
import TasyIpmModal from "./TasyIpmModal";
import MacroFilter, { IFilterParams } from "./MacroFilter";

const TASY_IPM_INTERNAL_ROUTE =
  process.env.REACT_APP_4UBUS +
  "/api/v1/TasyIPMPatientEncounterLog/ListTasyIPM";

const ipmTasyService = new NoPaginationApi<ITasyIpmLogs>(
  TASY_IPM_INTERNAL_ROUTE
);

const translationPath = "page.4uBus.logs.tasyXIpm.";

const TasyImpInternal = () => {
  const [open, setOpen] = useState(false);

  const [currentLog, setCurrentLog] = useState<ITasyIpmLogs>();

  const today = useMemo(() => new Date(), []);

  const [refresh, setRefresh] = useState(0);

  const [filterParams, setFilterParams] = useState<IFilterParams>({
    Date: today,
    Status: "",
    PatientEncounterNumber: "",
  });

  const { privileges } = UsePrivileges();

  const permission = !!privileges.find(
    (priv) =>
      priv.domainName === "all" || priv.domainName === "4ubuslogsipmfull"
  );

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const columns: GridColDef<ITasyIpmLogs>[] = [
    {
      field: "status",
      headerName: t(translationPath + "status") || "Status",
      maxWidth: 250,
      renderCell: (params) => {
        return params.value ?? "-";
      },
    },
    {
      field: "messageStatus",
      headerName: t(translationPath + "message_status") || "Message status",
      flex: 1,
      renderCell: (params) => {
        return params.value ? (
          <OverflowTooltip
            text={params.value}
            tooltip={params.value}
            variant="subtitle2"
          />
        ) : (
          "-"
        );
      },
    },
    {
      field: "created",
      headerName: t(translationPath + "created") || "Created",
      maxWidth: 150,
      renderCell: (params) => {
        if (!params.value) return "-";

        const initialDate = new Date(params.value);
        return format(initialDate, "Pp", {
          locale:
            language === "pt-BR" ? ptBR : language === "en-US" ? enUS : es,
        }).replaceAll(",", "");
      },
    },
    {
      field: "patientEncounterNumber",
      headerName:
        t(translationPath + "service_number") || "Service number (Tasy)",
      maxWidth: 250,
      renderCell: (params) => {
        return params.value ?? "-";
      },
    },
    {
      field: "individualIdentifier",
      headerName:
        t(translationPath + "individual_identifier") ||
        "Individual code (Tasy)",
      maxWidth: 250,
      renderCell: (params) => {
        return params.value ?? "-";
      },
    },
    {
      field: "tasyPatientAllergyCount",
      headerName: t(translationPath + "allergies") || "Allergies",
      maxWidth: 80,
      renderCell: (params) => {
        return params.value ?? 0;
      },
    },
    {
      field: "tasyPatientClinicalEvolutionCount",
      headerName: t(translationPath + "occurrences") || "Occurrences",
      maxWidth: 100,
      renderCell: (params) => {
        return params.value ?? 0;
      },
    },
    {
      field: "tasyPatientEmrLabExamResultCount",
      headerName: t(translationPath + "exams_lab") || "Exams lab",
      maxWidth: 100,
      renderCell: (params) => {
        return params.value ?? 0;
      },
    },
    {
      field: "tasyPatientEmrNonLaboratoryTestCount",
      headerName: t(translationPath + "exams_n_lab") || "Exams N Lab",
      maxWidth: 120,
      renderCell: (params) => {
        return params.value ?? 0;
      },
    },
    {
      field: "tasyPatientMedicineInUseCount",
      headerName: t(translationPath + "medicines") || "Medicines",
      maxWidth: 250,
      renderCell: (params) => {
        return params.value ?? 0;
      },
    },
  ];

  const closeModalHandler = () => setOpen(false);

  const openModalHandler = (params: GridRowParams<ITasyIpmLogs>) => {
    setCurrentLog(params.row);
    setOpen(true);
  };

  const onRefreshHandler = () => {
    setRefresh((p) => (p = +1));
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeModalHandler}
        title={`${t(translationPath + "logs")}`}
      >
        {currentLog && <TasyIpmModal id={currentLog.id} />}
      </Modal>
      <TableNoPagination<ITasyIpmLogs>
        title={t("components.menu.4ubus.logs.tasyxipminternal")}
        columns={columns}
        service={ipmTasyService}
        onRowClick={() => {}}
        showDefaultMacroFilters={false}
        showRowActions={permission}
        headerElements={
          <img
            src="/LGPD.png"
            width={80}
            height={"auto"}
            alt="LPGD guaranteed"
          />
        }
        serviceProps={{
          refresh,
          ...filterParams,
          Date: filterParams?.Date
            ? new Date(filterParams.Date.setHours(15))
                .toISOString()
                .substring(0, 10)
            : undefined,
        }}
        rowActions={() => {
          return [
            {
              text: t(translationPath + "view_logs"),
              action: openModalHandler,
              icon: <TocIcon />,
            },
          ];
        }}
        macroFilters={() => (
          <MacroFilter
            filterParams={filterParams}
            onFilterParams={setFilterParams}
            onRefresh={onRefreshHandler}
          />
        )}
      />
    </>
  );
};

export default TasyImpInternal;
