import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  FormContainer,
  FormInput,
  ISelectType,
  SwitchInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import MainApi from "../../../../services/mainApi.service";
import { useLocation, useParams } from "react-router";
import {
  createIpm,
  getIpmById,
  updateIpmById,
} from "../../../../services/ipm.service";
import { useCallback } from "react";
import { useAppSelector } from "../../../../store/store";
import { TRelationShipForm, relationShipSchema } from "./RelationShipSchema";

const translationPath = "page.4uBus.relationships.";

const RELATIONSHIP_TYPE_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/SetRelationshipType/";
const relationshipTypeService = new MainApi<ISelectType>(
  RELATIONSHIP_TYPE_ROUTE
);

const GENERIC_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const genericService = new MainApi<ISelectType>(GENERIC_ROUTE);

const RelationShipForm = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const currentPage = useAppSelector((state) =>
    state.menu.items.find(
      (menu) =>
        menu.link === `/${pathname.split("/").slice(4).slice(0, 3).join("/")}`
    )
  );

  const { extension } = useParams();

  const { sendRequest: save, loading: sLoading } = useFetch(createIpm);
  const { sendRequest: update, loading: uLoading } = useFetch(updateIpmById);
  const { sendRequest: get, loading: gLoading } = useFetch(getIpmById);

  const saveHandler = (v: TRelationShipForm) =>
    save({
      ...v,
      directionTypeId: v.directionType.id,
      setRelationshipTypeId: v.setRelationshipType.id,
    });

  const updateHandler = (v: TRelationShipForm, id: string) =>
    update({
      item: {
        ...v,
        directionTypeId: v.directionType.id,
        setRelationshipTypeId: v.setRelationshipType.id,
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TRelationShipForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TRelationShipForm = {
          ...data,
          originName: data.originName ?? "",
          originValue: data.originValue ?? "",
          destinyName: data.destinyName ?? "",
          destinyValue: data.destinyValue ?? "",
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  const title = `${t(translationPath + "relationship")} ${
    currentPage ? t(currentPage.name) : ""
  }`;

  return (
    <FormContainer<TRelationShipForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={title}
      schema={relationShipSchema}
      subtitle={t(translationPath + "new_relationship")}
      subtitleWatchField={"setRelationshipType.name"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} textAlign={"end"}>
            <SwitchInput
              name="default"
              label={t(translationPath + "default") || "Default"}
            />
            <SwitchInput
              name="enable"
              label={t(translationPath + "enable") || "Enable"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoComplete
              params={{ ExtensionCode: extension }}
              name="setRelationshipType"
              label={t(translationPath + "relationship_type")}
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              request={relationshipTypeService.list}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoComplete
              params={{ Identifier: "DIRECTION_TYPE" }}
              name="directionType"
              label={t(translationPath + "direction_type")}
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              request={genericService.list}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="originName"
              fullWidth
              label={t(translationPath + "origin_name") || "Origin Name"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="originValue"
              fullWidth
              label={t(translationPath + "origin_value") || "Origin Value"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="destinyName"
              fullWidth
              label={t(translationPath + "destiny_name") || "Destiny Name"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="destinyValue"
              fullWidth
              label={t(translationPath + "destiny_value") || "Destiny Value"}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default RelationShipForm;
