import { Button, useFetch, useNotificationContext } from "@4uhub/lib4uhub";
import { CircularProgress, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { approveHealthProgramCaptaion } from "../../../../../services/healthProgramCaptation.service";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const translationPath = "page.4uHcp.hcp.fundraising.";

interface IApproveCaptationProps {
  healthProgramCaptationId: string;
  redirect?: boolean;
  type?: "button" | "link";
  onRefresh?: () => void;
}

const ApproveCaptation = ({
  healthProgramCaptationId,
  redirect = false,
  type = "link",
  onRefresh,
}: IApproveCaptationProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { setMessage } = useNotificationContext();

  const { sendRequest, loading } = useFetch(approveHealthProgramCaptaion);

  const approveCaptation = useCallback(async () => {
    const { data, success } = await sendRequest([healthProgramCaptationId]);
    if (data && success) {
      setMessage({ message: t(translationPath + "approved_successfully") });
      if (redirect) {
        navigate(-1);
      }
      if (onRefresh) {
        onRefresh();
      }
    }
  }, [
    sendRequest,
    setMessage,
    onRefresh,
    navigate,
    redirect,
    t,
    healthProgramCaptationId,
  ]);

  if (loading) {
    return <CircularProgress size={20} />;
  }

  if (type === "button") {
    return (
      <Button variant="contained" size="small" onClick={approveCaptation}>
        {t(translationPath + "approve_captation")}
      </Button>
    );
  }

  return (
    <Link
      sx={{ cursor: "pointer" }}
      onClick={(event) => {
        event.stopPropagation();
        approveCaptation();
      }}
    >
      {t(translationPath + "approve_captation")}
    </Link>
  );
};

export default memo(ApproveCaptation);
