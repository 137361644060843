import { AutoComplete, useFormContext } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import MainApi from "../../../../../../services/mainApi.service";
import { TTicketServiceAreaForm } from "./TicketServiceAreaSchema";

const TICKET_EVENT_TYPE_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketEventType/";
const ticketEventTypeService = new MainApi(TICKET_EVENT_TYPE_ROUTE);

const TICKET_EVENTS_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketEvent/";
const ticketEventsService = new MainApi(TICKET_EVENTS_ROUTE);

const translationPath = "chatBot.page.service.ticketServiceArea.";

export const TicketEvent = memo(() => {
  const { t } = useTranslation();

  const { watch, setValue } = useFormContext<TTicketServiceAreaForm>();

  const ticketEventType = watch("ticketEventType");

  const cleanTicketEvent = useCallback(() => {
    setValue("ticketEvent", null);
  }, [setValue]);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <AutoComplete
          getOptionLabel={(option) => {
            return option.name;
          }}
          size="small"
          label={t(translationPath + "ticket_event_type")}
          name="ticketEventType"
          onValueChange={cleanTicketEvent}
          request={ticketEventTypeService.list}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <AutoComplete
          params={{ TicketEventTypeId: ticketEventType?.id }}
          getOptionLabel={(option) => {
            return option.name;
          }}
          size="small"
          label={t(translationPath + "ticket_event")}
          name="ticketEvent"
          disable={!ticketEventType}
          request={ticketEventsService.list}
        />
      </Grid>
    </>
  );
});
