import { memo } from "react";
import { useWatch } from "react-hook-form";
import { TBannerForm } from "../BannerSchema";
import { useFormContext } from "@4uhub/lib4uhub";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export enum EBannerLocale {
  OPEN_SITE_APP = "2",
  LOGIN_IN_SYSTEM = "1",
  MINI_BANNER_ABOVE = "3",
  MINI_BANNER_BELOW = "4",
  FULL_BANNER = "5",
}

interface IDimension {
  width: number;
  height: number;
}

export const DIMENSIONS: Record<
  EBannerLocale,
  { app: IDimension; desktop: IDimension }
> = {
  [EBannerLocale.MINI_BANNER_ABOVE]: {
    app: {
      width: 200,
      height: 100,
    },
    desktop: {
      width: 800,
      height: 200,
    },
  },
  [EBannerLocale.MINI_BANNER_BELOW]: {
    app: {
      width: 200,
      height: 100,
    },
    desktop: {
      width: 800,
      height: 200,
    },
  },
  [EBannerLocale.FULL_BANNER]: {
    app: {
      width: 360,
      height: 145,
    },
    desktop: {
      width: 1600,
      height: 300,
    },
  },
  [EBannerLocale.OPEN_SITE_APP]: {
    app: {
      width: 300,
      height: 400,
    },
    desktop: {
      width: 520,
      height: 300,
    },
  },
  [EBannerLocale.LOGIN_IN_SYSTEM]: {
    app: {
      width: 300,
      height: 400,
    },
    desktop: {
      width: 520,
      height: 300,
    },
  },
};

const RecommendedSize = () => {
  const { control } = useFormContext<TBannerForm>();

  const { t } = useTranslation();

  const { bannerLocals } = useWatch<TBannerForm>({
    control: control,
  });

  if (!bannerLocals) {
    return null;
  }

  return (
    <Stack component={"span"}>
      {bannerLocals.map((local) => {
        if (!local.code) return null;

        if (!(local.code in DIMENSIONS)) return null;

        const dimensions = DIMENSIONS[local.code];

        return (
          <Typography variant="caption" key={local.id} component={"span"}>
            <strong>{local.name}</strong>:{" "}
            {t("page.register.banners.recommendedSize", {
              desktopWidth: dimensions.desktop.width,
              desktopHeight: dimensions.desktop.height,
              appWidth: dimensions.app.width,
              appHeight: dimensions.app.height,
            })}
          </Typography>
        );
      })}
    </Stack>
  );
};

export default memo(RecommendedSize);
