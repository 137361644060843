import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Apps,
  Apps4uHub,
  AvatarComponent,
  LanguagePicker,
  ThemeSwitch,
  useFetch,
  EstablishmentChange,
  useConfig,
  useUser,
  UsePrivileges,
} from "@4uhub/lib4uhub";

import { menuSliceActions } from "../../store/slices/menu";
import { useAppDispatch } from "../../store/store";
import SettingsIcon from "@mui/icons-material/Settings";
import SubMenu from "./SubMenu/SubMenu";
import MenuItemList from "./MenuItemList/MenuItemList";
import MenuSecondaryItemList from "./MenuSecondaryItemList/MenuSecondaryItemList";
import UserAvatar from "./UserAvatar/UserAvatar";
import MenuRouteService, { IMenuRoute } from "../../services/menuRoute.service";
import { tokenService } from "../../services/token.service";
import { authService } from "../../services/auth.service";
import { useApp } from "../../store/appContex";
import { SideMenu } from "./SideMenu";
import useCrmLink from "../../hooks/useCrmLink";

const service = new MenuRouteService();

const getCode = (app?: Apps) => {
  switch (app) {
    case Apps.APP_4UHUB:
      return "1";
    case Apps.APP_4UCHATBOT:
      return "8";
    case Apps.APP_4USITEAPP:
      return "10";
    case Apps.APP_4UHCP:
      return "9";
    case Apps.APP_4UBUS:
      return "11";
    case Apps.APP_4USCHED:
      return "14";
    default:
      return "1";
  }
};

export const getSystem = (app?: Apps) => {
  switch (app) {
    case Apps.APP_4UHUB:
      return "dashboard";
    case Apps.APP_4UCHATBOT:
      return "chatBot";
    case Apps.APP_4USITEAPP:
      return "4uSiteApp";
    case Apps.APP_4UHCP:
      return "4uHcp";
    case Apps.APP_4UBUS:
      return "4uBus";
    case Apps.APP_4USCHED:
      return "4uSched";
    default:
      return "dashboard";
  }
};

const Menu = () => {
  const { configuration, closeMenu, openMenu } = useConfig();

  const {
    webAppearance: { menuOpen },
  } = configuration;

  const { eI } = useParams();

  const { crmClickHandler } = useCrmLink();

  const dispatch = useAppDispatch();

  const location = useLocation();

  const pathname = location.pathname;

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const { t } = useTranslation();

  const [routes, setRoutes] = useState<IMenuRoute[]>([]);

  const { sendRequest } = useFetch(service.list);

  const { user } = useUser();

  const { app } = useApp();

  const { privileges } = UsePrivileges();
  const permission = !!privileges.find(
    (priv) => priv.domainName === "settings" || priv.domainName === "all"
  );

  useEffect(() => {
    const loadRequest = async () => {
      const softwareCode = getCode(app);

      const { data } = await sendRequest(softwareCode);

      if (data) {
        setRoutes(
          data
            .map((route) => ({
              name: route.name,
              id: route.id,
              link: route.link,
              parentId: route.parentId,
              description: route.description,
              order: route.order,
              iconClass: route.iconClass,
              target: route.target,
              parent: route.parent,
            }))
            .sort((a, b) => a.order - b.order)
        );
      }
    };

    if (!app) return;
    loadRequest();
  }, [sendRequest, app]);

  useEffect(() => {
    dispatch(menuSliceActions.setItems(routes));

    const actualItemList = routes.filter((menu) => {
      if (menu.link) {
        if (
          pathname.includes("extensions-store") ||
          pathname.includes("extension-settings")
        ) {
          return menu.link === "/";
        }
        if (menu.link === `/`) {
          const system = getSystem(app);

          return `/e/${eI}/${system}` === pathname;
        } else {
          //Split the url by /
          const paths = pathname.split("/");

          /**Remove the 4 first itens of the url array 
            @example
            ['', 'e', '5', 'dashboard', 'register', 'individuals'] turns in ['register', 'individuals']
           * 
           */
          const urlPath = "/" + paths.splice(4).join("/");

          return urlPath === menu.link;
        }
      }
      return false;
    });

    const actualItem = actualItemList
      ? actualItemList.length === 1
        ? actualItemList[0]
        : actualItemList.find((item) =>
            actualItemList.some((i) => i.id === item.parentId)
          )
      : null;

    const actualItemParent = routes.find(
      (menu) => menu.id === actualItem?.parentId
    );

    if(actualItem){
      dispatch(menuSliceActions.setActualPage(actualItem))
    }

    if (actualItemParent) {
      dispatch(menuSliceActions.setOpenItem(actualItemParent));
    }
  }, [routes, dispatch, eI, pathname, app]);

  const openMenuHandler = useCallback(() => {
    if (!matches) {
      openMenu();
    }
  }, [matches, openMenu]);

  const closeMenuHandler = useCallback(() => {
    if (!matches) {
      closeMenu();
    }
  }, [matches, closeMenu]);

  const clickHandler = useCallback((link: string) => {
    window.open(link, "_blank");
  }, []);

  const isDashboard = useMemo(() => app === Apps.APP_4UHUB, [app]);

  const handleGetEstablishment = useCallback(
    async (establishmentId: string) => {
      return await authService.tokenByToken({ establishmentId });
    },
    []
  );

  return (
    <Stack
      direction={{ sm: "column", lg: "row" }}
      sx={{
        zIndex: 100,
      }}
      onMouseLeave={closeMenuHandler}
    >
      <Box
        sx={(theme) => ({
          padding: "5px",
          borderRadius: theme.shape.borderRadius - 7,
          overflowY: "auto",
          overflowX: "hidden",
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          backgroundColor:
            theme.palette.mode === "light" ? "#E9E9E9" : "#181818",
        })}
        onMouseEnter={openMenuHandler}
      >
        <Stack
          direction={{ sm: "column", lg: "row" }}
          height={{ lg: "100%", xl: "100%" }}
          gap={2}
        >
          <SideMenu>
            <Apps4uHub
              Apps={[
                {
                  logo: "/4uhubApps/4uChatBot.png",
                  link: `/e/${eI}/chatbot`,
                  name: Apps.APP_4UCHATBOT,
                  onClick: clickHandler,
                },
                {
                  link: `/e/${eI}/telemed`,
                  logo: "/4uhubApps/4uTelemed.png",
                  name: Apps.APP_4UTELEMED,
                  onClick: clickHandler,
                },
                {
                  logo: "/4uhubApps/4uCRM.png",
                  link: "",
                  name: Apps.APP_4UCRM,
                  onClick: crmClickHandler,
                },
                {
                  logo: "/4uhubApps/4uHub.png",
                  link: `/e/${eI}/dashboard`,
                  name: Apps.APP_4UHUB,
                  onClick: clickHandler,
                },
                {
                  logo: "/4uhubApps/logo_4uSiteApp.png",
                  link: `/e/${eI}/4uSiteApp`,
                  name: Apps.APP_4USITEAPP,
                  onClick: clickHandler,
                },
                {
                  logo: "/4uhubApps/4uHcp.png",
                  link: `/e/${eI}/4uHcp`,
                  name: Apps.APP_4UHCP,
                  onClick: clickHandler,
                },
                {
                  logo: "/4uhubApps/4uBus.png",
                  link: `/e/${eI}/4uBus`,
                  name: Apps.APP_4UBUS,
                  onClick: clickHandler,
                },
                {
                  logo: "/4uhubApps/4uSched.png",
                  link: `/e/${eI}/4uSched`,
                  name: Apps.APP_4USCHED,
                  onClick: clickHandler,
                },
              ]}
              exclude={app ? [app] : []}
              popOverProps={{
                transformOrigin: {
                  vertical: matches ? "top" : "bottom",
                  horizontal: "left",
                },
                anchorOrigin: {
                  vertical: matches ? "bottom" : "top",
                  horizontal: matches ? "left" : "right",
                },
                style: {
                  maxWidth: 280,
                },
              }}
              iconProps={{
                size: "medium",
                color: "inherit",
              }}
            />
            <EstablishmentChange
              getEstablishmentToken={handleGetEstablishment}
              onSaveToken={(access_token, refresh_token, index, id) => {
                tokenService.saveTokens(access_token, refresh_token, index, id);
              }}
              IconButtonProps={{
                color: "inherit",
              }}
            />
            <ThemeSwitch
              iconButtonProps={{
                size: "medium",
                color: "inherit",
              }}
            />
            <LanguagePicker />

            {permission && (
              <Tooltip title={t("page.settings.settings")} placement="right">
                <IconButton
                  color="inherit"
                  onClick={() => window.open(`/e/${eI}/dashboard/settings`)}
                >
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            )}

            <AvatarComponent userName={user.fullName} />
          </SideMenu>

          {menuOpen && (
            <Stack
              sx={(theme) => ({
                height: "100%",
                [theme.breakpoints.down("lg")]: {
                  height: "calc(100vh - 83.45px)",
                },
              })}
              pt={{ lg: 15, sm: 0 }}
              direction="column"
              alignItems={"flex-start"}
              justifyContent={"space-between"}
            >
              <MenuItemList activeAccordion={matches} />

              {matches ? (
                <Stack spacing={2} padding={2}>
                  {isDashboard && <MenuSecondaryItemList />}
                  <UserAvatar />
                </Stack>
              ) : (
                <>
                  {isDashboard && <MenuSecondaryItemList />}
                  <UserAvatar />
                </>
              )}
            </Stack>
          )}
        </Stack>
      </Box>
      {!matches && <SubMenu></SubMenu>}
    </Stack>
  );
};

export default memo(Menu);
