import { Grid } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import FormInformation from "../../../../../../components/FormInformation/FormInformation";
import { IImportsById } from "../../../../../../models/individuals";
import { formatToCpf } from "../../../../../../utils/conversions";

const translationPath = "page.register.importList.";

export const IndividualData = memo((props: IImportsById) => {
  const { t } = useTranslation();

  const {
    document,
    birthDate,
    gender,
    foreigner,
    cnsCard,
    addressDescription,
    addressNumber,
    addressComplement,
    addressNeighborhood,
    addressCity,
    addressNeighborhoodCode,
    addressCityIbgeCode,
    addressZipCode,
    country,
    identityCard,
    email,
    whatsapp,
    phone1,
    phone2,
    status,
  } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormInformation
          label={t(translationPath + "document")}
          data={formatToCpf(document)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInformation label={t(translationPath + "status")} data={status} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInformation
          label={t(translationPath + "birth_date")}
          data={birthDate}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInformation label={t(translationPath + "gender")} data={gender} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInformation
          label={t(translationPath + "foreigner")}
          data={
            foreigner === "true"
              ? t(translationPath + "yes")
              : t(translationPath + "no")
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInformation label={t(translationPath + "cns")} data={cnsCard} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormInformation
          label={t(translationPath + "address")}
          data={addressDescription}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInformation
          label={t(translationPath + "number")}
          data={addressNumber}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInformation
          label={t(translationPath + "neighborhood")}
          data={addressNeighborhood}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormInformation
          label={t(translationPath + "complement")}
          data={addressComplement}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInformation
          label={t(translationPath + "city")}
          data={addressCity}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInformation
          label={t(translationPath + "zip_code")}
          data={addressZipCode}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInformation
          label={t(translationPath + "country")}
          data={country}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInformation
          label={t(translationPath + "neighborhood_code")}
          data={addressNeighborhoodCode}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInformation
          label={t(translationPath + "city_ibge_code")}
          data={addressCityIbgeCode}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInformation
          label={t(translationPath + "identity_card")}
          data={identityCard}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInformation label={t(translationPath + "email")} data={email} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInformation label="WhatsApp" data={whatsapp} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInformation label={t(translationPath + "phone")} data={phone1} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInformation label={t(translationPath + "phone")} data={phone2} />
      </Grid>
    </Grid>
  );
});
