import { useTranslation } from "react-i18next";

const ptBrConjunctions = [
  "e",
  "também",
  "nem",
  "mas também",
  "não só",
  "mas",
  "porém",
  "todavia",
  "entretanto",
  "contudo",
  "ou",
  "ora",
  "porque",
  "pois",
  "porquanto",
  "por isso",
  "de",
  "da",
  "do",
];

const enUsConjunctions = [
  "after",
  "although",
  "as",
  "as if",
  "as long as",
  "as much as",
  "as soon as",
  "because",
  "before",
  "by the time",
  "even if",
  "even though",
  "if",
  "in case",
  "in order that",
  "just in case",
  "now that",
  "once",
  "only",
  "only if",
  "rather than",
  "since",
  "so",
  "supposing",
  "than",
  "that",
  "though",
  "till",
  "unless",
  "until",
  "when",
  "whenever",
  "wherever",
  "whether or not",
  "whether",
  "while",
  "in",
  "from the",
  "and",
  "of",
];

const esConjunctions = [
  "y",
  "e",
  "ni",
  "tanto",
  "como",
  "cuanto",
  "así",
  "o",
  "bien",
  "ya",
  "ora",
  "sea",
  "pero",
  "mas",
  "no",
  "sino",
  "sino que",
  "no solo",
  "sino también",
  "en",
  "desde el",
  "del",
];

const useCoordinatesConjuctions = (text: string) => {
  const {
    i18n: { language: locale },
    t,
  } = useTranslation();

  const translatedText = t(text);

  let conjunctions: string[] = [];

  if (locale === "pt-BR") {
    conjunctions = ptBrConjunctions;
  }
  if (locale === "en-US") {
    conjunctions = enUsConjunctions;
  }
  if (locale === "es") {
    conjunctions = esConjunctions;
  }

  let words = translatedText.split(" ");

  for (let i = 0; i < words.length; i++) {
    if (!conjunctions.includes(words[i])) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
  }

  return words.join(" ");
};

export default useCoordinatesConjuctions;
