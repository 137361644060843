import { TabPanel } from "@4uhub/lib4uhub";
import { motion } from "framer-motion";
import SettingsMenuItem from "../../components/Settings/SettingsMenuItem";
import { useAppSelector } from "../../store/store";

interface ISettingsMenu {
  value: number;
  index: number;
  description: string;
  submenuDescription?: string[];
}

const SettingsMenu = ({
  value,
  index,
  description,
  submenuDescription,
}: ISettingsMenu) => {
  const menus = useAppSelector((state) => state.menu.items);
  const menu = menus.filter((menu) =>
    menu.parent ? menu.parent.description === description : null
  );
  const submenus = menus.filter((menu) =>
    menu.parent && menu.parent.description
      ? submenuDescription?.includes(menu.parent.description)
      : null
  );

  return (
    <TabPanel index={index} value={value} key={index}>
      <motion.main
        id="main"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
      >
        {menu.map((item) => (
          <SettingsMenuItem
            link={item.link}
            name={item.name}
            icon={item.iconClass ?? null}
            description={item.description || ""}
            submenuDescription={
              submenuDescription
                ? submenuDescription.find((desc) => desc === item.description)
                : undefined
            }
            submenu={submenus.filter((sub) => sub.parentId === item.id)}
            key={item.id}
          />
        ))}
      </motion.main>
    </TabPanel>
  );
};

export default SettingsMenu;
