import { z } from "zod";

export const importSchema = z
  .object({
    file: z.any(),
  })
  .superRefine((val, ctx) => {
    if (!val.file) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "O arquivo é obrigatório",
        path: ["file"],
      });
    }
  });

export type TImportForm = z.infer<typeof importSchema>;
