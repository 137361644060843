import { AutoCompleteMulti, useFetch } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import SitesAppsService from "../../../../../services/sitesApps.service";
import { ISiteAppSelector } from "../../../../../models/tickets-service";

const translationPath = "page.register.banners.";

const siteAppExtensionService = new SitesAppsService();

const BannerApps = () => {
  const [siteAppOptions, setSiteAppOptions] = useState<ISiteAppSelector[]>([]);

  const { sendRequest } = useFetch(
    siteAppExtensionService.siteAppExtensionList
  );

  const loadSiteApp = useCallback(async () => {
    const { data, success } = await sendRequest({ ExtensionCode: "7" });
    if (data && success) {
      setSiteAppOptions(data.map((item) => item.siteApp));
    }
  }, [sendRequest]);

  useEffect(() => {
    loadSiteApp();
  }, [loadSiteApp]);

  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12}>
      <AutoCompleteMulti
        size="small"
        label={t(translationPath + "site_apps")}
        name="siteApps"
        options={siteAppOptions}
      />
    </Grid>
  );
};

export default memo(BannerApps);
