import { Button, Modal } from "@4uhub/lib4uhub";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IImportsById } from "../../../../../models/individuals";
import { IndividualData } from "./IndividualData/IndividualData";

const translationPath = "page.register.importList.";

export const IndividualModal = memo((props: IImportsById) => {
  const { t } = useTranslation();

  const { name } = props;

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <>
      <Modal open={open} onClose={handleClose} title={name}>
        <IndividualData {...props} />
      </Modal>
      <Button
        variant="outlined"
        size="small"
        startIcon={<VisibilityIcon />}
        onClick={handleOpen}
      >
        {t(translationPath + "view")}
      </Button>
    </>
  );
});
