import { z } from "zod";

const translationPath = "page.4uHcp.hcp.healthProgramImports.errors.";

const healthProgramSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
  },
  { invalid_type_error: translationPath + "health_program" }
);

export const healthProgramImportSchema = z.object({
  id: z.string().optional(),
  importPersonName: z.string().min(1, translationPath + "import_person_name"),
  originOfDemand: z.string().optional(),
  file: z
    .instanceof(File, { message: translationPath + "file" })
    .refine((val) => val, translationPath + "file"),
  healthProgram: healthProgramSchema,
});

export type THealthProgramImportForm = z.infer<
  typeof healthProgramImportSchema
>;
