import { memo } from "react";
import { Link, Tooltip } from "@mui/material";
import { IEvaluationForm } from "./model";
import { useNavigate } from "react-router-dom";
import { getEIFromUrl } from "../../../services/establishment.service";

interface IFormProps {
  data: IEvaluationForm;
}
/* /evaluation/evaluationForms/7f378f28-1f5a-4059-9046-1e9a24f8a37e/edit*/

const Form: React.FC<IFormProps> = ({ data }) => {
  const navigate = useNavigate();

  const ei = getEIFromUrl();

  const onClickHandler = () => {
    navigate(`/e/${ei}/dashboard/evaluation/evaluationForms/${data.id}/edit`);
  };

  return (
    <Tooltip title={data.title}>
      <Link
        component="button"
        variant="body2"
        onClick={onClickHandler}
        textOverflow={"ellipsis"}
        overflow={`hidden`}
      >
        {data.title}
      </Link>
    </Tooltip>
  );
};

export default memo(Form);
