import { memo } from "react";
import { Grid } from "@mui/material";
import { useWatch } from "react-hook-form";
import { useFormContext } from "@4uhub/lib4uhub";

import { useSchedule as use4uSched } from "../../../../ScheduleProvider";
import { useSchedule } from "./useSchedule";
import { TAppointment } from "../../schema";
import { SchedulesList } from "./SchedulesList";
import ScheduleCalendar from "./ScheduleCalendar/ScheduleCalendar";

const Schedule = () => {
  const { control } = useFormContext<TAppointment>();

  const dateForm = useWatch({ name: "search.date", control });

  const { allAvaiableColor, fullDayColor, someAvaiableColor } = use4uSched();

  const {
    date,
    dates,
    datesLoading,
    onDateChange,
    onVisibleMonthsChange,
    schedules,
    schedulesLoading,
  } = useSchedule(dateForm.toDateString());

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={"auto"}>
        <ScheduleCalendar
          date={date}
          defaultValue={new Date(dateForm)}
          dates={dates}
          datesLoading={datesLoading}
          onDateChange={onDateChange}
          allAvaiableColor={allAvaiableColor}
          fullDayColor={fullDayColor}
          someAvaiableColor={someAvaiableColor}
          onMonthChange={onVisibleMonthsChange}
        />
      </Grid>
      <Grid item xs={12} md={true}>
        <SchedulesList
          schedules={schedules}
          name="schedule"
          loading={schedulesLoading}
        />
      </Grid>
    </Grid>
  );
};

export default memo(Schedule);
