import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { EStatusAvailability, IAvaiableDate } from "../model";
import { getDayInfo } from "../utils";
import { memo, useMemo } from "react";

const CalendarDay = (
  props: PickersDayProps<Date> & {
    avaiableDates?: IAvaiableDate[];
    fullDayColor?: string;
    someAvaiableColor?: string;
    allAvaiableColor?: string;
    disableNotAvaiableDates?: boolean;
  }
) => {
  const {
    fullDayColor,
    someAvaiableColor,
    allAvaiableColor,
    day,
    outsideCurrentMonth,
    avaiableDates,
    selected,
    disableNotAvaiableDates = false,
    ...other
  } = props;

  const date = getDayInfo(day, avaiableDates);

  const backgroundColor = useMemo(() => {
    if (!disableNotAvaiableDates && date?.statusAvailability === EStatusAvailability.NON_AVAIABLE) {
      return fullDayColor;
    }

    if (date?.statusAvailability === EStatusAvailability.EXISTS_AVAIABLE) {
      return someAvaiableColor;
    }

    if (date?.statusAvailability === EStatusAvailability.ALL_AVAIABLE) {
      return allAvaiableColor;
    }

    return "transparent";
  }, [
    date,
    allAvaiableColor,
    someAvaiableColor,
    fullDayColor,
    disableNotAvaiableDates,
  ]);

  const disabled =
    disableNotAvaiableDates &&
    (!date || date.statusAvailability === EStatusAvailability.NON_AVAIABLE);

  return (
    <PickersDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      disabled={disabled}
      selected={selected}
      style={{ backgroundColor: selected ? undefined : backgroundColor }}
    />
  );
};

export default memo(CalendarDay);
