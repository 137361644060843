import { memo, useCallback } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import Hour from "./Hour";
import { IAvaiableSchedule, ISchedule } from "../../../../model";

interface IScheduleItemProps {
  data: IAvaiableSchedule;
  onSelectedHour: (hour?: ISchedule) => void;
  selectedHour?: ISchedule;
}

const ScheduleItem: React.FC<IScheduleItemProps> = ({
  data: { schedules, descriptionSite, calendarShortName },
  onSelectedHour,
  selectedHour,
}) => {
  const handlePress = useCallback(
    (hour: ISchedule) => {
      if (hour.identifier === selectedHour?.identifier) {
        onSelectedHour(undefined);
      } else {
        onSelectedHour(hour);
      }
    },
    [onSelectedHour, selectedHour]
  );

  return (
    <Stack gap={1}>
      {calendarShortName && <Typography>{calendarShortName}</Typography>}
      <Typography sx={{ fontWeight: "bold" }}>{descriptionSite}</Typography>
      <Grid container direction={"row"} spacing={1}>
        {schedules.map((s) => (
          <Hour
            schedule={s}
            key={s.identifier}
            onPress={handlePress}
            selected={s.identifier === selectedHour?.identifier}
          />
        ))}
      </Grid>
    </Stack>
  );
};

export default memo(ScheduleItem);
