import {
  AutoCompleteUncontrolled,
  ISelectType,
  useFetch,
} from "@4uhub/lib4uhub";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MainApi from "../../../../services/mainApi.service";
import { useParams } from "react-router-dom";

export interface ISelectRelationshipTypeProps {
  relationshipType: ISelectType | null;
  setRelationshipType: (value: ISelectType | null) => void;
}

const RELATIONSHIP_TYPE_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/SetRelationshipType/";
const relationshipTypeService = new MainApi<ISelectType>(
  RELATIONSHIP_TYPE_ROUTE
);

const translationPath = "page.4uBus.relationships.";

export const SelectRelationshipType = memo(
  ({ relationshipType, setRelationshipType }: ISelectRelationshipTypeProps) => {
    const { extension } = useParams();

    const { t } = useTranslation();

    const [options, setOptions] = useState<ISelectType[]>([]);

    const { sendRequest } = useFetch(relationshipTypeService.list);

    const fetchOptions = useCallback(async () => {
      if (!extension) return;
      const { data, success } = await sendRequest({ ExtensionCode: extension });
      if (data && success) {
        setOptions(data);
      }
    }, [sendRequest, extension]);

    useEffect(() => {
      fetchOptions();
    }, [fetchOptions]);

    return (
      <AutoCompleteUncontrolled
        label={t(translationPath + "relationship_type")}
        size="small"
        value={relationshipType}
        onValueSelected={setRelationshipType}
        options={options}
      />
    );
  }
);
