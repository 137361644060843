import { memo, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, useFetch, useNotificationContext } from "@4uhub/lib4uhub";
import { Box } from "@mui/material";
import FormTextEditor from "../../UI/TextEditor/FormTextEditor";
import { RunSqlCodeActions } from "./RunSqlCodeActions/RunSqlCodeActions";
import { HTML_TAGS_VALIDATOR } from "../../../utils/Validators/regexValidators";
import "./styles.css";
import { executeSql } from "../../../services/executeSql.service";
import { IRunSqlCodeProps } from "./models";
import { useTranslation } from "react-i18next";
import { executeSqlSchema, TExecuteSqlForm } from "./runSqlSchema";

const translationPath = "page.settings.system.executeSql.";

export const RunSqlCode = memo(
  ({ onLoading, onGetSqlReturn }: IRunSqlCodeProps) => {
    const { t } = useTranslation();

    const { sendRequest, loading } = useFetch(executeSql);

    const { setMessage } = useNotificationContext();

    const methods = useForm<TExecuteSqlForm>({
      resolver: zodResolver(executeSqlSchema),
    });
    const { handleSubmit } = methods;

    const onSubmit = useCallback(
      async (values: TExecuteSqlForm) => {
        const { data, success } = await sendRequest({
          sql: values.sql.replace(HTML_TAGS_VALIDATOR, ""),
        });
        if (data && success) {
          setMessage({
            message: t(translationPath + "execute_successfully"),
            type: "success",
          });
          onGetSqlReturn(data.data);
        }
      },
      [sendRequest, onGetSqlReturn, setMessage, t]
    );

    useEffect(() => {
      onLoading(loading);
    }, [onLoading, loading]);

    return (
      <Form {...methods}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <FormTextEditor name="sql" onlyCode />
          <RunSqlCodeActions loading={loading} />
        </Box>
      </Form>
    );
  }
);
