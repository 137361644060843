import { useCallback, useEffect, useState } from "react";
import { useFetch, useFormContext } from "@4uhub/lib4uhub";
import { endOfWeek, format, lastDayOfMonth } from "date-fns";

import { TAppointment, TSearchConsult, TSearchExam } from "../../schema";
import { ScheduleAppointmentService } from "../../../../Schedule.service";
import {
  ETasyScheduleTypeCode,
  IAvaiableDate,
  IAvaiableSchedule,
} from "../../../../model";

export const useSchedule = (defaultDate: string) => {
  const [month, setMonth] = useState<Date>(new Date(defaultDate));

  const { getValues, resetField } = useFormContext<TAppointment>();

  const [datesLoading, setDatesLoading] = useState(false);

  const [schedulesLoading, setSchedulesLoading] = useState(false);

  const [dates, setDates] = useState<IAvaiableDate[]>([]);

  const [schedules, setSchedules] = useState<IAvaiableSchedule[]>([]);

  const [date, setDate] = useState<Date | null>(null);

  const { sendRequest } = useFetch(
    ScheduleAppointmentService.CalendarAllConsultationExamAvailabilityByDate
  );

  const { sendRequest: getConsultationSchedules } = useFetch(
    ScheduleAppointmentService.getConsultationAvaiableSchedules
  );

  const { sendRequest: getExamSchedules } = useFetch(
    ScheduleAppointmentService.getExamAvaiableSchedules
  );

  const fetchConsulationDates = useCallback(
    async (search: TSearchConsult) => {
      const CalendarIdentifierList = [];

      if (search.professional) {
        CalendarIdentifierList.push(Number(search.professional.id));
      }

      const { data, success } = await sendRequest({
        CalendarDateStart: new Date(search.date).toDateString(),
        CalendarDateEnd: endOfWeek(lastDayOfMonth(month)).toDateString(),
        MedicalSpecialtyCode: search.speciality?.id ?? "",
        CalendarIdentifierList: CalendarIdentifierList,
        ScheduleShiftCode:
          search.period.code === "-1" ? null : search.period.code,
        SituationCalendar: "-1",
        TasyScheduleTypeCode: ETasyScheduleTypeCode.CONSULTATION,
      });
      if (success && data) {
        setDates(data);
      }
    },
    [sendRequest, month]
  );

  const fetchExamDates = useCallback(
    async (search: TSearchExam) => {
      const CalendarIdentifierList = [];

      if (search.examGroup) {
        CalendarIdentifierList.push(Number(search.examGroup.id));
      }

      const { data, success } = await sendRequest({
        CalendarDateStart: new Date(search.date).toDateString(),
        CalendarDateEnd: endOfWeek(lastDayOfMonth(month)).toDateString(),
        CalendarIdentifierList: CalendarIdentifierList,
        ScheduleShiftCode:
          search.period.code === "-1" ? null : search.period.code,
        SituationCalendar: "-1",
        TasyScheduleTypeCode: ETasyScheduleTypeCode.EXAM,
      });

      if (success && data) {
        setDates(data);
      }
    },
    [sendRequest, month]
  );

  const fetchDates = useCallback(async () => {
    const { search } = getValues();
    setDatesLoading(true);
    if (search.type === ETasyScheduleTypeCode.CONSULTATION) {
      await fetchConsulationDates(search);
    } else {
      await fetchExamDates(search);
    }
    setDatesLoading(false);
  }, [fetchConsulationDates, getValues, fetchExamDates]);

  const fetchConsultationSchedules = useCallback(
    async (search: TSearchConsult, pDate: Date) => {
      const { data, success } = await getConsultationSchedules({
        Date: format(pDate, "MM/dd/yyyy"),
        MedicalSpecialtyCode: search.speciality?.id,
        MonthYear: format(month, "MM/yyyy"),
        CalendarIdentifier: search.professional?.id
          ? String(search.professional?.id)
          : undefined,
        ScheduleShiftCode:
          search.period.code === "-1" ? null : search.period.code,
      });
      if (success && data) {
        setSchedules(data);
      }
    },
    [month, getConsultationSchedules]
  );

  const fetchExamSchedules = useCallback(
    async (search: TSearchExam, pDate: Date) => {
      const { data, success } = await getExamSchedules({
        Date: format(pDate, "MM/dd/yyyy"),
        GroupingIdentifier: Number(search.examGroup.id),
        MonthYear: format(month, "MM/yyyy"),
        ScheduleShiftCode:
          search.period.code === "-1" ? null : search.period.code,
      });
      if (success && data) {
        setSchedules(data);
      }
    },
    [month, getExamSchedules]
  );

  const fetchSchedules = useCallback(
    async (pDate: Date) => {
      const { search } = getValues();
      setSchedulesLoading(true);
      if (search.type === ETasyScheduleTypeCode.CONSULTATION) {
        await fetchConsultationSchedules(search, pDate);
      } else {
        await fetchExamSchedules(search, pDate);
      }
      setSchedulesLoading(false);
    },
    [getValues, fetchConsultationSchedules, fetchExamSchedules]
  );

  const onVisibleMonthsChange = useCallback((month: Date) => {
    setMonth(month);
  }, []);

  const onDateChange = useCallback(
    (d: Date | null) => {
      if (d) {
        fetchSchedules(d);
      }
      setDate(d);
      resetField("schedule");
    },
    [fetchSchedules, resetField]
  );

  useEffect(() => {
    fetchDates();
  }, [fetchDates]);

  return {
    onDateChange,
    onVisibleMonthsChange,
    date,
    dates,
    schedules,
    datesLoading,
    schedulesLoading,
  };
};
