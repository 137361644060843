import { Grid, Stack } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "@4uhub/lib4uhub";

import { TAppointment } from "../../schema";
import ConfirmDate from "../../Stepper/ConfirmDate";
import Row from "../../../../../../components/Row";

const Confirm = () => {
  const { getValues } = useFormContext<TAppointment>();

  const { t } = useTranslation();


  const values = getValues();

  return (
    <Grid container gap={3} flexDirection={"row"}>
      <Stack spacing={1}>
        <Row
          label={t("components.scheduleAssistant.newSchedule.confirm.descriptionSite")}
          value={values.schedule.descriptionSite}
        />
        <Row
          label={t("components.scheduleAssistant.newSchedule.confirm.patient")}
          value={values.patient.individualName}
        />

        <Row
          label={t("components.scheduleAssistant.newSchedule.confirm.insurance")}
          value={values.insurance.name}
        />
      </Stack>
      <Grid item xs={true}>
        <ConfirmDate
          duration={values.schedule.minutesDuration}
          startHour={new Date(values.schedule.calendarStartDate)}
        />
      </Grid>
    </Grid>
  );
};

export default memo(Confirm);
