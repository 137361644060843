import { GlobalAxios } from "../axios/axios-config";
import { IAddImport, IImportIndividuals } from "../models/individuals";

const BASE_URL = process.env.REACT_APP_MAIN;

const INDIVIDUAL_ROUTE = BASE_URL + "/api/v1/IndividualImports";

export const addImport = (item: IAddImport) => {
  const formData = new FormData();
  formData.append("File", item.file);

  return GlobalAxios.post(`${INDIVIDUAL_ROUTE}/Import`, formData);
};

export const importIndividuals = (item: IImportIndividuals) => {
  return GlobalAxios.get(`${INDIVIDUAL_ROUTE}/Import`, { params: item });
};
