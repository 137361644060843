import { NavLink } from "react-router-dom";
import { Box, Button, Stack } from "@mui/material";
import { OverflowTooltip } from "@4uhub/lib4uhub";

import { IMenuRoute } from "../../../services/menuRoute.service";
import DynamicIcon from "../../UI/DynamicIcon";
import { useApp } from "../../../store/appContex";
import useCoordinatesConjuctions from "../../../hooks/useCoordinatesConjunctions";
import { getSystem } from "../Menu";
import { useAppDispatch } from "../../../store/store";
import { menuSliceActions } from "../../../store/slices/menu";

interface ISubMenuItemProps {
  data: IMenuRoute;
  onClick: (link?: string, target?: string) => void;
}
const SubMenuItem: React.FC<ISubMenuItemProps> = ({ data, onClick }) => {
  const { app } = useApp();

  const dispatch = useAppDispatch();

  const name = useCoordinatesConjuctions(data.name);

  const system = getSystem(app);

  const realLink = data.link === "/" ? system : `${system}${data.link}`;

  const onClickHandler = () => {
    dispatch(menuSliceActions.setActualPage(data));
    onClick(realLink, data.target);
  };

  return (
    <>
      <NavLink
        end={data.link === "/"}
        to={realLink}
        style={{
          color: "inherit",
          width: "100%",
        }}
      >
        {({ isActive }) => (
          <Button
            fullWidth
            key={data.id}
            color={isActive ? "primary" : "inherit"}
            onClick={() => {
              onClickHandler();
            }}
          >
            <Stack
              direction={"row"}
              gap={0.1}
              alignItems={"center"}
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "100%",
              }}
            >
              <DynamicIcon icon={data.iconClass} fontSize="small" />
              <Box
                sx={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  marginLeft: 1,
                  overflow: "hidden",
                  textAlign: "left",
                }}
              >
                <OverflowTooltip
                  text={name}
                  tooltip={name}
                />
              </Box>
            </Stack>
          </Button>
        )}
      </NavLink>
    </>
  );
};

export default SubMenuItem;
