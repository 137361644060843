import { Stack, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Dispatch, SetStateAction, memo } from "react";
import { useTranslation } from "react-i18next";

import FiltersActions from "../../../../components/FiltersActions/FiltersActions";
import useFilter from "../../../../hooks/useFilter";

interface IMacroFilterProps {
  onFilter: Dispatch<SetStateAction<IFilterParams>>;
  filterParams: IFilterParams;
  onRefresh: () => void;
}

export interface IFilterParams {
  Status: string;
  TasyEncounterIntegrationId: string;
  TasyIndividualIntegrationId: string;
  IpmIndividualIntegrationId: string;
  IpmEncounterIntegrationId: string;
  CreatedInitial: Date;
  CreatedEnd?: Date;
  refresh?: boolean;
}

const translationPath = "page.4uBus.logs.logTasyImp.";

const MacroFilter: React.FC<IMacroFilterProps> = ({
  onFilter,
  filterParams,
  onRefresh,
}) => {
  const {
    disableFilterBtn,
    filter,
    showCleanBtn,
    initialState,
    setFilter,
    cleanFilter,
  } = useFilter(filterParams);

  const { t } = useTranslation();

  const filterHandler = () => {
    onFilter(filter);
  };

  const cleanHandler = () => {
    onFilter(initialState.current);
    cleanFilter();
  };

  return (
    <Stack flexDirection={"row"} gap={2}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={filter?.CreatedInitial}
          slotProps={{
            textField: {
              size: "small",
              fullWidth: true,
            },
            field: {
              clearable: false,
            },
          }}
          onChange={(v) => {
            if (v) {
              const newValue = new Date(v);

              setFilter({ CreatedInitial: newValue });
            }
          }}
          format={"dd/MM/yyyy"}
        />
      </LocalizationProvider>

      <TextField
        value={filter?.Status}
        size="small"
        label={t(translationPath + "status")}
        fullWidth
        onChange={(e) =>
          setFilter({
            Status: e.target.value,
          })
        }
      />

      <TextField
        value={filter?.TasyEncounterIntegrationId}
        size="small"
        type="number"
        label={t(translationPath + "TasyEncounterIntegrationId")}
        fullWidth
        onChange={(e) =>
          setFilter({
            TasyEncounterIntegrationId: e.target.value,
          })
        }
      />

      <TextField
        value={filter?.TasyIndividualIntegrationId}
        size="small"
        type="number"
        label={t(translationPath + "TasyIndividualIntegrationId")}
        fullWidth
        onChange={(e) =>
          setFilter({
            TasyIndividualIntegrationId: e.target.value,
          })
        }
      />

      <FiltersActions
        onFilter={filterHandler}
        onClean={cleanHandler}
        onRefresh={onRefresh}
        disableFilterBtn={disableFilterBtn}
        showCleanBtn={showCleanBtn}
      />
    </Stack>
  );
};
export default memo(MacroFilter);
