import { Button, useFormContext } from "@4uhub/lib4uhub";
import { Box } from "@mui/material";
import { memo, useCallback } from "react";
import { IRunSqlCodeActionsProps } from "./models";
import { HTML_TAGS_VALIDATOR } from "../../../../utils/Validators/regexValidators";
import { TExecuteSqlForm } from "../runSqlSchema";
import { useTranslation } from "react-i18next";

const translationPath = "page.settings.system.executeSql.";

export const RunSqlCodeActions = memo(
  ({ loading }: IRunSqlCodeActionsProps) => {
    const { t } = useTranslation();

    const { setValue, watch } = useFormContext<TExecuteSqlForm>();

    const cleanHandler = useCallback(() => {
      setValue("sql", "");
    }, [setValue]);

    const sqlEmpty =
      !watch("sql") ||
      watch("sql").replace(HTML_TAGS_VALIDATOR, "").length === 0;

    return (
      <Box
        sx={{
          mt: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          gap: 1,
        }}
      >
        <Button
          disabled={loading || sqlEmpty}
          variant="outlined"
          onClick={cleanHandler}
        >
          {t(translationPath + "clean")}
        </Button>
        <Button
          disabled={loading || sqlEmpty}
          loading={loading}
          type="submit"
          variant="contained"
        >
          {t(translationPath + "execute")}
        </Button>
      </Box>
    );
  }
);
