import "./Editor.css";

import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import { Box } from "@mui/material";
import MenuBar from "./MenuBar";
import { FontSize } from "./extensions/fontSize";
import { IMenuBarProps } from "./models";
import { useEffect } from "react";
interface IEditorComponent {
  value?: string;
  error?: boolean;
  readOnly?: boolean;
  maxHeight?: string;
  menuBarProps?: IMenuBarProps;
  onlyCode?: boolean;
  onChange?: (value: string) => any;
}

const EditorComponent: React.FC<IEditorComponent> = ({
  value = "",
  error = false,
  readOnly = false,
  maxHeight = "600px",
  menuBarProps = {
    fontSize: true,
    markFormatting: true,
    selectColor: true,
    textFormatting: true,
  },
  onlyCode = false,
  onChange,
}) => {
  const editor = useEditor({
    extensions: [
      Color,
      TextStyle,
      FontSize.configure({
        initialFontSize: "12",
      }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
        codeBlock: onlyCode
          ? {
              exitOnArrowDown: false,
              exitOnTripleEnter: false,
              languageClassPrefix: "language-",
            }
          : undefined,
      }),
    ],
    content: value ? value : "",
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      if (onChange) {
        onChange(html);
      }
    },
    editable: !readOnly,
  });

  useEffect(() => {
    if (onlyCode) {
      if (editor && (!value || value.length === 0)) {
        editor.commands.setContent("<pre><code></code></pre>");
      }
      return;
    }
    if (editor && (!value || value.length === 0)) {
      editor.commands.setContent("<p></p>");
    }
  }, [onlyCode, value, editor]);

  useEffect(() => {
    if (
      (onlyCode && value.length === 0 && editor) ||
      (onlyCode && editor && value === "<p></p>")
    ) {
      editor.commands.setCodeBlock();
    }
  }, [editor, value, onlyCode]);

  return (
    <Box
      sx={(theme) => ({
        border: !onlyCode ? "1px solid" : undefined,
        borderRadius: theme.shape.borderRadius - 7,
        borderColor: error ? theme.palette.error.main : theme.palette.divider,
        backgroundColor: !onlyCode ? undefined : "#0D0D0D",
        overflow: "auto",
        maxHeight: maxHeight,
        position: "relative",
      })}
    >
      {!readOnly && !onlyCode && (
        <Box
          padding={2}
          paddingBottom={0}
          sx={(theme) => ({
            position: "sticky",
            top: 0,
            zIndex: 2,
            backgroundColor: theme.palette.background.default,
            paddingBottom: "10px",
            borderBottom: "1px solid ",
            borderBottomColor: theme.palette.divider,
          })}
        >
          <MenuBar editor={editor} menuBarProps={menuBarProps} />
        </Box>
      )}

      <Box padding={2}>
        <EditorContent editor={editor} />
      </Box>
    </Box>
  );
};

export default EditorComponent;
