import { Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  DateInput,
  FormCheckBox,
  FormContainer,
  FormInput,
  Treturn,
  useFetch,
} from "@4uhub/lib4uhub";
import { useCallback } from "react";

import {
  createPrescriptionGenerationRule,
  getPrescriptionGenerationRuleById,
  updatePrescriptionGenerationRuleById,
} from "../../../../../../../services/prescriptionGenerationRule.service";
import { ruleSchema, TRuleForm } from "./ruleSchema";

const translationPath = "page.settings.4uBus.externalExamPrescription.rules.";

const Rule = () => {
  const { t } = useTranslation();

  const { sendRequest: save, loading: sLoading } = useFetch(
    createPrescriptionGenerationRule
  );
  const { sendRequest: update, loading: uLoading } = useFetch(
    updatePrescriptionGenerationRuleById
  );
  const { sendRequest: get, loading: gLoading } = useFetch(
    getPrescriptionGenerationRuleById
  );

  const updateHandler = (v: TRuleForm, id: string) =>
    update({
      item: {
        ...v,
        initialDate: v.initialDate.toISOString(),
        endDate: v.endDate.toISOString(),
        patientInsuranceCode:
          v.patientInsuranceCode !== null ||
          v.patientInsuranceCode !== undefined
            ? Number(v.patientInsuranceCode)
            : 0,
        execPhysicianCode:
          v.execPhysicianCode !== null && v.execPhysicianCode !== undefined
            ? v.execPhysicianCode
            : undefined,
      },
      id: id,
    });

  const saveHandler = (v: TRuleForm) =>
    save({
      ...v,
      initialDate: v.initialDate.toISOString(),
      endDate: v.endDate.toISOString(),
      patientInsuranceCode:
        v.patientInsuranceCode !== null || v.patientInsuranceCode !== undefined
          ? Number(v.patientInsuranceCode)
          : 0,
      execPhysicianCode:
        v.execPhysicianCode !== null && v.execPhysicianCode !== undefined
          ? v.execPhysicianCode
          : undefined,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TRuleForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TRuleForm = {
          ...data,
          identifier: data.identifier ? data.identifier.toString() : "",
          initialDate: new Date(data.initialDate),
          endDate: new Date(data.endDate),
          patientInsuranceCode: data.patientInsuranceCode
            ? data.patientInsuranceCode.toString()
            : "",
        };
        return { data: newData, success };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TRuleForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "rule")}
      schema={ruleSchema}
      subtitle={t(translationPath + "new_rule")}
      subtitleWatchField={"medicalProcedureTypeCode"}
      fields={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography fontWeight="bold">
              {t(translationPath + "if")}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <DateInput
              name="initialDate"
              label={t(translationPath + "initial_date")}
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DateInput name="endDate" label={t(translationPath + "end_date")} />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="medicalProcedureTypeCode"
              fullWidth
              label={t(translationPath + "medical_procedure_type_code")}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="patientInsuranceCode"
              fullWidth
              label={t(translationPath + "patient_insurance_code")}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Divider sx={{ my: 2, width: "100%" }} />
            <Typography fontWeight="bold">
              {t(translationPath + "then")}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormCheckBox
              name="isExceptions"
              label={`${t(translationPath + "exception")}`}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="sectorCode"
              fullWidth
              label={t(translationPath + "sector_code")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              size="small"
              name="execPhysicianCode"
              fullWidth
              label={t(translationPath + "exec_physician_code")}
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default Rule;
