import { Avatar, Chip, Grid, Stack, Tooltip, Typography } from "@mui/material";

import ExtensionIcon from "@mui/icons-material/Extension";
import { useTranslation } from "react-i18next";
import { IExtension } from "../../models/extensions-store";
import ExtensionsPrice from "./ExtensionsPrice";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { useEffect, useRef, useState } from "react";
import { currencyFormatter } from "./ExtensionsCardPrices";

const translationPath = "components.extensionsStoreDetail.";

const ExtensionDetailHeader = ({ extension }: { extension: IExtension }) => {
  const { t } = useTranslation();

  const file = extension.extensionMediaFiles.find(
    (file) => file.mediaType.code === "1"
  );

  const prices = extension.extensionPricingRules;

  const titleRef = useRef<HTMLElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      if (titleRef.current) {
        setIsTruncated(
          titleRef.current.scrollWidth > titleRef.current.clientWidth
        );
      }
    };

    checkTruncation();
    window.addEventListener("resize", checkTruncation);

    return () => {
      window.removeEventListener("resize", checkTruncation);
    };
  }, [extension.title]);

  return (
    <Grid item xs={12} sm={12} md={12} mt={2}>
      <Stack display={"flex"} flexDirection={"row"} gap={2}>
        <Avatar alt="Extension" sx={{ width: 56, height: 56, mb: 2 }}>
          {file && <img src={file.cdnDomain + "/" + file.filePath} alt="" />}
          {!file && <ExtensionIcon />}
        </Avatar>
        <Stack>
          <Tooltip
            title={extension.title}
            disableHoverListener={!isTruncated}
            disableFocusListener={!isTruncated}
          >
            <Typography
              ref={titleRef}
              variant="h6"
              sx={{
                maxWidth: 480,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {extension.title}
            </Typography>
          </Tooltip>
          {extension.instaledOn.length === 0 && (
            <Stack
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              gap={2}
            >
              {prices.length === 0 && (
                <ExtensionsPrice
                  color="success"
                  chipLabel={`${t(translationPath + "free")}`}
                  variant="filled"
                />
              )}
              {prices
                .filter((item) => item.typePricing.code === "1")
                .map((price) => (
                  <ExtensionsPrice
                    key={price.id}
                    color="default"
                    icon={<DownloadForOfflineIcon color="action" />}
                    tooltipTitle={`${t(translationPath + "tooltip_install")}`}
                    chipLabel={currencyFormatter.format(price.value)}
                    variant="outlined"
                  />
                ))}
              {prices
                .filter((item) => item.typePricing.code === "2")
                .map((price) => (
                  <ExtensionsPrice
                    key={price.id}
                    color="default"
                    icon={<CalendarMonthIcon color="action" />}
                    tooltipTitle={`${t(translationPath + "tooltip_month")}`}
                    chipLabel={currencyFormatter.format(price.value)}
                    variant="outlined"
                  />
                ))}
            </Stack>
          )}
          {extension.instaledOn.length !== 0 && (
            <Chip
              sx={{ width: "fit-content" }}
              label={t(translationPath + "installed")}
              size="small"
            />
          )}
        </Stack>
      </Stack>
      {extension.extensionTags.length !== 0 && (
        <Stack>
          <Typography>{t(translationPath + "tags")}:</Typography>
          <Stack
            mt={2}
            display={"flex"}
            flexDirection={"row"}
            gap={1}
            flexWrap={"wrap"}
          >
            {extension.extensionTags.map((tag) => (
              <Chip
                sx={{ width: "fit-content" }}
                key={tag.id}
                label={tag.name}
                size="small"
              />
            ))}
          </Stack>
        </Stack>
      )}
    </Grid>
  );
};

export default ExtensionDetailHeader;
