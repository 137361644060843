export function jsonToFormData<T extends object>(json: T) {
  const formData = new FormData();
  Object.keys(json).forEach((key) => {
    const value = json[key as keyof T];
    if (typeof value === "number") {
      formData.append(key, value.toString());
    } else if (typeof value === "string") {
      formData.append(key, value);
    } else if (value instanceof Date) {
      formData.append(key, value.toISOString());
    } else if (typeof value === "object" && value !== null) {
      formData.append(key, JSON.stringify(value));
    } else {
      throw new Error(`Unexpected type for value ${key}`);
    }
  });
  return formData;
}

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const documentFormat = (text: string) => {
  return text.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

export const REMOVE_CURLY_BRACKETS = /\{\{|\}\}/gi;

export const formatToCpf = (value: string) => {
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};
