import { useTranslation } from "react-i18next";
import LogIpmTasy from "./LogIpmTasy";

const TASY_X_IPM = process.env.REACT_APP_4UBUS + "/api/v1/ForuBusLogIpmTasy";

const IpmTasy = () => {
  const { t } = useTranslation();

  return (
    <LogIpmTasy
      route={TASY_X_IPM}
      title={t("components.menu.4ubus.logs.ipmtasy")}
    />
  );
};

export default IpmTasy;
