import { Stack, SxProps, Theme, useTheme } from "@mui/material";
import { DIMENSIONS, EBannerLocale } from "../BannersLocals/RecommendedSize";

interface IPreview {
  locales: EBannerLocale[];
  src?: string;
  sx?: SxProps<Theme>;
}

const MobilePreview: React.FC<IPreview> = ({ locales, src, sx }) => {
  const { palette } = useTheme();

  const hasBanner = (b: EBannerLocale) => locales.includes(b);

  const getAspectRatio = (b: EBannerLocale) =>
    DIMENSIONS[b].app.width / DIMENSIONS[b].app.height;

  return (
    <Stack
      sx={sx}
      style={{
        padding: 5,
        borderRadius: 10,
        border: `10px solid ${
          palette.mode === "dark" ? palette.grey[100] : palette.grey[800]
        }`,
        aspectRatio: 9 / 16,
        gap: 10,
      }}
    >
      {hasBanner(EBannerLocale.FULL_BANNER) && (
        <img
          src={src}
          width={"100%"}
          style={{
            aspectRatio: getAspectRatio(EBannerLocale.FULL_BANNER),
            objectFit: "cover",
          }}
          alt={"Full banner example"}
        />
      )}

      <div
        style={{
          width: "100%",
          aspectRatio: 6 / 1,
          backgroundColor: palette.divider,
        }}
      ></div>

      {hasBanner(EBannerLocale.MINI_BANNER_ABOVE) && (
        <Stack flexDirection={"row"} gap={1} overflow={"hidden"}>
          <img
            src={src}
            width={"60%"}
            style={{
              aspectRatio: getAspectRatio(EBannerLocale.MINI_BANNER_ABOVE),
              objectFit: "cover",
            }}
            alt={"Mini banner example"}
          />
          <img
            src={src}
            width={"60%"}
            style={{
              aspectRatio: getAspectRatio(EBannerLocale.MINI_BANNER_ABOVE),
              objectFit: "cover",
            }}
            alt={"Mini banner example"}
          />
        </Stack>
      )}

      <Stack style={{ width: "70%" }} direction={"row"} gap={1}>
        <div
          style={{
            width: "80%",
            backgroundColor: palette.divider,
            aspectRatio: 1 / 1,
          }}
        ></div>
        <div
          style={{
            width: "80%",
            backgroundColor: palette.divider,
            aspectRatio: 1 / 1,
          }}
        ></div>
        <div
          style={{
            width: "80%",
            backgroundColor: palette.divider,
            aspectRatio: 1 / 1,
          }}
        ></div>
      </Stack>

      {hasBanner(EBannerLocale.MINI_BANNER_BELOW) && (
        <Stack flexDirection={"row"} gap={1} overflow={"hidden"}>
          <img
            src={src}
            width={"60%"}
            style={{
              aspectRatio: getAspectRatio(EBannerLocale.MINI_BANNER_BELOW),
            }}
            alt={"Mini banner example"}
          />
          <img
            src={src}
            width={"60%"}
            style={{
              aspectRatio: getAspectRatio(EBannerLocale.MINI_BANNER_BELOW),
            }}
            alt={"Mini banner example"}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default MobilePreview;
