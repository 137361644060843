import { FormInput } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
interface IContactValueProps {
  index: number;
}

const ContactValue: React.FC<IContactValueProps> = ({ index }) => {
  return (
    <Grid item xs={12} sm={8}>
      <FormInput size="small" name={`contacts.${index}.value`} fullWidth />
    </Grid>
  );
};

export default ContactValue;
