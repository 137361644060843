import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../../services/mainApi.service";
import { Chip, Stack } from "@mui/material";
import {
  AutoCompleteUncontrolled,
  ISelectType,
  ModalFilters,
  Table,
  useFetch,
} from "@4uhub/lib4uhub";
import { IGetBanners } from "../../../../models/banners";
import { useCallback, useEffect, useState } from "react";
import SitesAppsService from "../../../../services/sitesApps.service";

const BANNER_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Banner/";
const bannerService = new MainApiService<IGetBanners>(BANNER_ROUTE);

const BANNER_LOCALS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const bannerLocalsService = new MainApiService<IGetBanners>(
  BANNER_LOCALS_ROUTE
);

const siteAppService = new SitesAppsService();

const translationPath = "page.register.banners.";

const BannersPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [siteApp, setSiteApp] = useState<ISelectType | null>(null);

  const [status, setStatus] = useState<ISelectType | null>(null);

  const [bannerLocal, setBannerLocal] = useState<ISelectType | null>(null);

  const [siteAppOptions, setSiteAppOptions] = useState<ISelectType[]>([]);

  const [bannerLocalsOptions, setBannerLocalsOptions] = useState<ISelectType[]>(
    []
  );

  const [filterParams, setFilterParams] = useState<Record<string, any>>();

  const filterHandler = () => {
    setFilterParams({
      SiteAppId: siteApp ? siteApp.id : undefined,
      Enable: status ? (status.id === "1" ? true : false) : undefined,
      BannerLocalCode: bannerLocal ? bannerLocal.code : undefined,
    });
  };

  const cleanFilterHandler = () => {
    setFilterParams(undefined);
    setSiteApp(null);
    setBannerLocal(null);
    setStatus(null);
  };

  const { sendRequest } = useFetch(siteAppService.siteAppExtensionList);

  const { sendRequest: getBannerLocals } = useFetch(bannerLocalsService.list);

  const fetchBannerLocalsOptions = useCallback(async () => {
    const { data, success } = await getBannerLocals({
      Identifier: "BANNER_LOCALS",
    });
    if (data && success) {
      setBannerLocalsOptions(data);
    }
  }, [getBannerLocals]);

  const fetchSiteAppOptions = useCallback(async () => {
    const { data, success } = await sendRequest({ ExtensionCode: "7" });
    if (data && success) {
      setSiteAppOptions(
        data.map((item) => ({ id: item.siteApp.id, name: item.siteApp.name }))
      );
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchSiteAppOptions();
    fetchBannerLocalsOptions();
  }, [fetchSiteAppOptions, fetchBannerLocalsOptions]);

  const columns: GridColDef<IGetBanners>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "title",
      headerName: t(translationPath + "title") || "Title",
    },
    {
      field: "displayOrder",
      headerName: t(translationPath + "display_order") || "Display order",
    },
    {
      field: "bannerLocals",
      headerName: t(translationPath + "banner_locals") || "Banner Locals",
      renderCell: (params) => {
        return (
          <Stack
            flexDirection={"row"}
            gap={1}
            flexWrap={"wrap"}
            paddingBlock={1}
          >
            {params.row.bannerLocals.map((banner) => (
              <Chip size="small" key={banner.id} label={banner.name} />
            ))}
          </Stack>
        );
      },
      valueGetter(params) {
        return params.row.bannerLocals.length;
      },
    },
    {
      field: "enable",
      headerName: t(translationPath + "enable") || "Enable",
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={
              params.value
                ? t(translationPath + "active")
                : t(translationPath + "inactive")
            }
            color={params.value ? "success" : "error"}
          />
        );
      },
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IGetBanners>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IGetBanners>
      showDefaultMacroFilters={false}
      service={bannerService}
      columns={columns}
      title={t(translationPath + "banners")}
      searchInput
      onAdd={handleAdd}
      getRowHeight={() => "auto"}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      showDeleteButton={false}
      defaultActions={false}
      serviceProps={filterParams}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Searh for a banner"
      }
      macroFilters={() => (
        <ModalFilters
          actionsDisabled={!siteApp && !status && !bannerLocal}
          badgeCount={
            filterParams
              ? Object.keys(filterParams).filter(
                  (chave) => filterParams[chave] !== undefined
                ).length
              : 0
          }
          onCleanFilter={cleanFilterHandler}
          onFilter={filterHandler}
        >
          <>
            <AutoCompleteUncontrolled
              value={siteApp}
              onValueSelected={setSiteApp}
              label={t(translationPath + "application")}
              size="small"
              options={siteAppOptions}
            />
            <AutoCompleteUncontrolled
              value={bannerLocal}
              onValueSelected={setBannerLocal}
              label={t(translationPath + "banner_local")}
              size="small"
              options={bannerLocalsOptions}
            />
            <AutoCompleteUncontrolled
              value={status}
              onValueSelected={setStatus}
              label={t(translationPath + "status")}
              size="small"
              options={[
                { id: "1", name: t(translationPath + "active") },
                { id: "2", name: t(translationPath + "inactive") },
              ]}
            />
          </>
        </ModalFilters>
      )}
      addButtonLabel={t(translationPath + "add_banner") || "Add Banner"}
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default BannersPage;
