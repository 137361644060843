import {
  AutoCompleteUncontrolled,
  ISelectType,
  ModalFilters,
} from "@4uhub/lib4uhub";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";

interface IFundraisingFilterProps {
  selectedStatus: ISelectType | null;
  onCleanFilter: () => void;
  onFilter: (v: ISelectType | null) => void;
  options: ISelectType[];
}

const translationPath = "page.4uHcp.hcp.fundraising.";

const FundraisingFilter: React.FC<IFundraisingFilterProps> = ({
  selectedStatus,
  options,
  onCleanFilter,
  onFilter,
}) => {
  const { t } = useTranslation();

  const [status, setStatus] = useState<ISelectType | null>(selectedStatus);

  const onFilterHandler = () => {
    onFilter(status);
  };

  const onCleanHandler = () => {
    onCleanFilter()
    setStatus(null)
  }

  return (
    <ModalFilters
      actionsDisabled={!status}
      badgeCount={selectedStatus ? 1 : 0}
      onCleanFilter={onCleanHandler}
      onFilter={onFilterHandler}
    >
      <AutoCompleteUncontrolled
        label={t(translationPath + "captation_status") || "Captation status"}
        size="small"
        value={status}
        onValueSelected={setStatus}
        options={options}
      />
    </ModalFilters>
  );
};

export default memo(FundraisingFilter);
