import { AutoComplete, ISelectType } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import MainApi from "../../../../../../services/mainApi.service";

const translationPath = "chatBot.page.service.ticketServiceArea.";

const TICKET_DEFAULT_DOC_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketDefaultDoc/";
const ticketDefaultDocService = new MainApi<ISelectType[]>(
  TICKET_DEFAULT_DOC_ROUTE
);

const TicketDefaultDocField = () => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={6}>
      <AutoComplete
        getOptionLabel={(option) => {
          return option.name;
        }}
        size="small"
        label={t(translationPath + "ticket_default_doc")}
        name="ticketDefaultDoc"
        request={ticketDefaultDocService.list}
      />
    </Grid>
  );
};

export default memo(TicketDefaultDocField);
