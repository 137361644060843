import { useNotificationContext } from "@4uhub/lib4uhub";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const useCopy = () => {
  const { setMessage } = useNotificationContext();
  const { t } = useTranslation();

  const copy = useCallback(
    async (text: string) => {
      try {
        navigator.clipboard.writeText(text);
        setMessage({
          message: t("components.usecopy.copied_successfully"),
          type: "success",
        });
      } catch (error) {
        setMessage({
          message: t("components.usecopy.copied_error"),
          type: "error",
        });
      }
    },
    [setMessage, t]
  );

  return { copy };
};

export default useCopy;
