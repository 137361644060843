import { FormTabs, ISelectType, useFetch } from "@4uhub/lib4uhub";
import { Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import MainApi from "../../../../../../services/mainApi.service";
import FundRaisingTable from "./FundRaisingTable";

const GENERIC_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const translationPath = "page.4uHcp.hcp.fundraising.";

const genericService = new MainApi<ISelectType>(GENERIC_ROUTE);

const FundraisingPage = () => {
  const [captationTypes, setCaptationTypes] = useState<ISelectType[]>([]);

  const [captationStatus, setCaptationStatus] = useState<ISelectType[]>([]);

  const { t } = useTranslation();

  const { sendRequest } = useFetch(genericService.list);

  const fetchCaptationTypes = useCallback(async () => {
    const { data, success } = await sendRequest({
      Identifier: "HealthProgramCaptationType",
    });
    if (data && success) {
      setCaptationTypes(data);
    }
  }, [sendRequest]);

  const fetchCaptationStatus = useCallback(async () => {
    const { data, success } = await sendRequest({
      Identifier: "HealthProgramCaptationStatus",
    });
    if (data && success) {
      setCaptationStatus(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchCaptationTypes();
    fetchCaptationStatus();
  }, [fetchCaptationStatus, fetchCaptationTypes]);

  return (
    <Stack height={"100%"}>
      <Typography variant="h5" mb={2}>
        {t(translationPath + "fundraising")}
      </Typography>
      <FormTabs
        sx={{ height: "calc(100% - 43px)" }}
        tabs={captationTypes.map((t) => ({
          component: (
            <FundRaisingTable
              HealthProgramCaptationTypeId={t.id}
              status={captationStatus}
            />
          ),
          label: t.name,
        }))}
      />
    </Stack>
  );
};

export default FundraisingPage;
