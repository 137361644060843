import { Table } from "@4uhub/lib4uhub";
import { GridColDef } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { IImportsById } from "../../../../models/individuals";
import MainApi from "../../../../services/mainApi.service";
import { formatToCpf } from "../../../../utils/conversions";
import { IndividualModal } from "./IndividualModal/IndividualModal";

const INDIVIDUALS_IMPORTS_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/IndividualImports/list";

const individualsImportsService = new MainApi<IImportsById>(
  INDIVIDUALS_IMPORTS_ROUTE
);

const TRANSLATION_PATH = "page.register.importList.";

const ImportList = () => {
  const { id } = useParams();

  const { t } = useTranslation();

  const columns: GridColDef<IImportsById>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: t(TRANSLATION_PATH + "name") || "Name",
      },
      {
        field: "document",
        headerName: t(TRANSLATION_PATH + "document") || "Document",
        maxWidth: 150,
        renderCell: (params) => {
          return params.value ? formatToCpf(params.value) : "-";
        },
      },
      {
        field: "status",
        headerName: t(TRANSLATION_PATH + "status") || "Status",
      },
      {
        field: "country",
        headerName: "",
        maxWidth: 150,
        renderCell: (params) => {
          if (!params.row) return "-";

          return <IndividualModal {...params.row} />;
        },
      },
    ],
    [t]
  );

  const props = useMemo(() => {
    return {
      IndividualImportId: id,
    };
  }, [id]);

  return (
    <Table<IImportsById>
      showDefaultMacroFilters={false}
      service={individualsImportsService}
      columns={columns}
      title={t(TRANSLATION_PATH + "imports_individuals") || "Imports"}
      searchInput={false}
      showButton={false}
      serviceProps={props}
      showDeleteButton={false}
      defaultActions={false}
      defaultPageSize={20}
      pageSizeOptions={[5, 10, 20]}
      backButton
      searchInputPlaceHolder={
        t(TRANSLATION_PATH + "search_imports") || "Searh for an import"
      }
      addButtonLabel={t(TRANSLATION_PATH + "add_import") || "Add Import"}
      confirmOptions={{
        title: t(TRANSLATION_PATH + "confirm.title"),
        description: t(TRANSLATION_PATH + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default ImportList;
