import { Button } from "@4uhub/lib4uhub";
import DownloadIcon from "@mui/icons-material/Download";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import useDownload from "../../../../../hooks/useDownload";

const INDIVIDUAL_ROUTE = `${process.env.REACT_APP_MAIN}/api/v1/IndividualImports/GetCsvTemplate`;

const TRANSLATION_PATH = "page.register.individualsImports.";

export const DownloadModel = memo(() => {
  const { downloadFileParams } = useDownload();

  const { t } = useTranslation();

  const downloadTemplate = useCallback(() => {
    downloadFileParams(INDIVIDUAL_ROUTE, null, "Template.csv");
  }, [downloadFileParams]);

  return (
    <Button
      variant="contained"
      startIcon={<DownloadIcon />}
      onClick={downloadTemplate}
    >
      {t(`${TRANSLATION_PATH}download_model`)}
    </Button>
  );
});
