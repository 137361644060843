import { z } from "zod";

const translationPath = "page.4uHcp.hcp.workGroups.errors.";

const language = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional(),
  },
  { invalid_type_error: translationPath + "language" }
);

const healthProgramWorkGroupTypeLanguageSchema = z.object({
  name: z.string().min(1, translationPath + "name"),
  description: z.string().optional().nullable(),
  language: language,
});

const healthProgramWorkGroupMemberSchema = z.object({
  identifier: z.string(),
  individualName: z.string(),
  document: z.string(),
  establishmentId: z.string(),
  genderCode: z.string().nullish(),
  birthDate: z.string().nullish(),
  dataOrigin: z.string().nullish(),
  lastModified: z.string().nullish(),
  lastModifiedByUserName: z.string().nullish(),
  created: z.string().nullish(),
  createdByUserName: z.string().nullish(),
  emrNumber: z.number().nullish(),
  individualNameAbbreviation: z.string().nullish(),
  individualSocialName: z.string().nullish(),
  susCard: z.string().nullish(),
  individualsGeneralRegistration: z.string().nullish(),
  deathDate: z.string().nullish(),
  bloodGroupTyping: z.string().nullish(),
  bloodGroupTypingName: z.string().nullish(),
  bloodGroupRHFactor: z.string().nullish(),
  bloodGroupRHFactorName: z.string().nullish(),
  placeOfBirthIBGECode: z.string().nullish(),
  placeOfBirthIBGEName: z.string().nullish(),
  genderName: z.string().nullish(),
  nationalityCode: z.string().nullish(),
  nationalityName: z.string().nullish(),
  addressCityIBGEName: z.string().nullish(),
  individualDDI: z.string().nullish(),
  individualDDD: z.string().nullish(),
  individualCellPhone: z.string().nullish(),
  whatsapp: z.string().nullish(),
  mail: z.string().nullish(),
  systemExternalCode: z.string().nullish(),
  individualAddresses: z.string().nullish(),
});

const healthProgramWorkGroupTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.number().optional(),
  },
  { invalid_type_error: translationPath + "work_group_type" }
);

export const workGroupSchema = z.object({
  id: z.string().optional(),
  healthProgramWorkGroupType: healthProgramWorkGroupTypeSchema,
  healthProgramWorkGroupMembers: z
    .array(healthProgramWorkGroupMemberSchema)
    .nullish(),
  healthProgramWorkGroupLanguages: z
    .array(healthProgramWorkGroupTypeLanguageSchema)
    .min(1, translationPath + "languages"),
});

export type TWorkGroupForm = z.infer<typeof workGroupSchema>;
