import { z } from "zod";

const translationPath = "page.settings.4uBus.zScan.examSendRule.errors.";

export const examSendRuleSchema = z
  .object({
    id: z.string().optional(),
    enable: z.boolean(),
    procClassificationIntegrationId: z
      .string()
      .min(1, translationPath + "proc_classification_integration_id"),
    targetMachine: z.string().min(1, translationPath + "target_machine"),
    initialDate: z.date({
      invalid_type_error: translationPath + "initial_date",
    }),
    endDate: z.date({ invalid_type_error: translationPath + "end_date" }),
  })
  .superRefine((val, ctx) => {
    if (val.initialDate > val.endDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translationPath + "initial_date_bigger",
        path: ["initialDate"],
      });
    }
  });

export type TExamSendRuleForm = z.infer<typeof examSendRuleSchema>;
