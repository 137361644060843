import { ISelectType, Table } from "@4uhub/lib4uhub";
import { Box, Chip, SxProps, Theme } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { memo, useState } from "react";

import { IHealthProgramsFundraising } from "../../../../../../models/health-programs-fundraising";
import ApproveCaptation from "../ApproveCaptation";
import DisapproveCaptation from "../DisapproveCaptation";
import MainApi from "../../../../../../services/mainApi.service";
import FundraisingFilter from "./FundraisingFilter";

const translationPath = "page.4uHcp.hcp.fundraising.";

const HEALTH_PROGRAMS_ROUTE =
  process.env.REACT_APP_MAIN +
  "/api/v1/HealthProgramCaptation/GetHealthProgramCaptationsWithPagination";

const healthProgramsService = new MainApi<any>(HEALTH_PROGRAMS_ROUTE);

interface IFundRaisingTableProps {
  HealthProgramCaptationTypeId: string;
  status: ISelectType[];
  sx?: SxProps<Theme>;
}

const FundRaisingTable: React.FC<IFundRaisingTableProps> = ({
  HealthProgramCaptationTypeId,
  status,
  sx,
}) => {
  const { healthProgramId } = useParams();

  const [refresh, setRefresh] = useState(0);

  const [selectedStatus, setSelectedStatus] = useState<ISelectType | null>(
    null
  );

  const cleanFilterHandler = () => {
    setSelectedStatus(null);
  };

  const navigate = useNavigate();

  const { t } = useTranslation();

  const onRefresh = () => {
    setRefresh((prev) => prev + 1);
  };

  const columns: GridColDef<IHealthProgramsFundraising>[] = [
    {
      field: "healthProgram",
      headerName: t(translationPath + "healthProgram") || "Name",
      flex: 1,
      renderCell: (params) => {
        return params.value?.name;
      },
    },
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
      flex: 1,
    },
    {
      field: "healthProgramCaptationType",
      headerName: t(translationPath + "captation_type") || "Captation type",
      flex: 1,
      maxWidth: 300,
      renderCell: (params) => {
        return params.value ? params.value.name : "-";
      },
    },
    {
      field: "healthProgramCaptationStatus",
      headerName: t(translationPath + "captation_status") || "Captation status",
      flex: 1,
      maxWidth: 200,
      renderCell: (params) => {
        if (!params.value) return "-";

        const code = params.value?.code;
        return (
          <Chip
            size="small"
            label={params.value.name}
            color={
              code === "1" ? "success" : code === "2" ? "error" : "default"
            }
          />
        );
      },
    },
    {
      field: "approveCaptation",
      headerName: "",
      maxWidth: 170,
      renderCell: (params) => {
        if (params.row.healthProgramCaptationStatus.code !== "3") return "-";

        return (
          <ApproveCaptation
            healthProgramCaptationId={params.row.id}
            onRefresh={onRefresh}
          />
        );
      },
    },
    {
      field: "disaprroveCaptation",
      headerName: "",
      maxWidth: 170,
      renderCell: (params) => {
        if (params.row.healthProgramCaptationStatus.code !== "3") return "-";

        return (
          <DisapproveCaptation
            healthProgramCaptationId={params.row.id}
            onRefresh={onRefresh}
          />
        );
      },
    },
  ];

  const handleRowClick = (
    params: GridRowParams<IHealthProgramsFundraising>
  ) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Box sx={{ height: "100%"}}>
      <Table<IHealthProgramsFundraising>
        showDefaultMacroFilters={false}
        columns={columns}
        service={healthProgramsService}
        serviceProps={{
          HealthProgramId: healthProgramId,
          HealthProgramCaptationTypeId,
          HealthProgramCaptationStatusId: selectedStatus?.id,
          refresh: refresh,
        }}
        onRowClick={handleRowClick}
        defaultPageSize={20}
        pageSizeOptions={[5, 10, 20]}
        showButton={false}
        searchInput
        showDeleteButton={false}
        defaultActions={false}
        searchInputPlaceHolder={
          t(translationPath + "search") || "Search for a fundrasing"
        }
        macroFilters={() => (
          <FundraisingFilter
            selectedStatus={selectedStatus}
            options={status}
            onCleanFilter={cleanFilterHandler}
            onFilter={setSelectedStatus}
          />
        )}
        confirmOptions={{
          title: t(translationPath + "confirm.title"),
          description: t(translationPath + "confirm.description"),
          confirmationButtonProps: {
            variant: "contained",
          },
        }}
      />
    </Box>
  );
};

export default memo(FundRaisingTable);
