import { useConfig } from "@4uhub/lib4uhub";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { getEstToken } from "../services/establishment.service";

const isLocalHost = window.location.hostname === "localhost";

const APP_CRM_LINK = isLocalHost
  ? "http://localhost:3008"
  : process.env.REACT_APP_4UCRM;

const useCrmLink = () => {
  const { establishments } = useConfig();

  const { eI } = useParams();

  const actualEstablishment = useMemo(
    () => establishments.find((e) => e.tenantSequential === Number(eI)),
    [establishments, eI]
  );

  const crmClickHandler = useCallback(
    (redirect?: string) => {
      const crmDomain = actualEstablishment?.crmDomain;

      let link = `${crmDomain || APP_CRM_LINK}/login/?token=${
        getEstToken()?.token
      }&establishment=${actualEstablishment?.id}&code=${eI}`;

      if (redirect) {
        link += `&redirect=${redirect}`;
      }

      window.open(link, "_blank");
    },
    [actualEstablishment, eI]
  );

  return { crmClickHandler };
};

export default useCrmLink;
