import { z } from "zod";
import { HTML_TAGS_VALIDATOR } from "../../../utils/Validators/regexValidators";

const translationPath = "page.settings.system.executeSql.errors.";

export const executeSqlSchema = z
  .object({
    sql: z.string().min(1, translationPath + "sql"),
  })
  .superRefine((val, ctx) => {
    const code = val.sql.replace(HTML_TAGS_VALIDATOR, "");
    if (code.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translationPath + "sql",
        path: ["sql"],
      });
    }
  });

export type TExecuteSqlForm = z.infer<typeof executeSqlSchema>;
