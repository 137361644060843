import { memo, useCallback, useMemo, useState } from "react";
import { IconButton } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import { BaseTable, Modal } from "@4uhub/lib4uhub";
import { GridColDef, GridRowIdGetter } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

import { IHealthProgramHistory } from "../../../../../../models/health-programs-participantes";
import useDate from "../../../../../../hooks/useDate";

interface IMemberHistoryProps {
  data: IHealthProgramHistory[];
}

const translationPath = "page.4uHcp.hcp.participants.membersHistory.";

const MemberHistory: React.FC<IMemberHistoryProps> = ({ data }) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const { localeFormat } = useDate();

  const onClickOpenModalHandler = () => {
    setOpen(true);
  };

  const onCloseModalHandler = () => {
    setOpen(false);
  };

  const columns: GridColDef<IHealthProgramHistory>[] = useMemo(
    () => [
      {
        field: "initialDate",
        headerName: t(translationPath + "initialDate") || "Start Date",
        renderCell: (row) => localeFormat(new Date(row.value), "P pp"),
        flex: 1,
      },
      {
        field: "endDate",
        headerName: t(translationPath + "endDate") || "End Date",
        renderCell: (row) =>
          row.value
            ? localeFormat(new Date(row.value), "P pp")
            : "-",
        flex: 1,
      },
    ],
    [localeFormat, t]
  );

  const getRowId: GridRowIdGetter<IHealthProgramHistory> = useCallback(
    (r) => r.endDate + r.initialDate,
    []
  );

  return (
    <>
      <Modal
        onClose={onCloseModalHandler}
        title={t(translationPath + "title") || "Members History"}
        open={open}
      >
        <BaseTable
          rows={data}
          columns={columns}
          getRowId={getRowId}
          initialState={{
            sorting: {
              sortModel: [{ field: "initialDate", sort: "desc" }],
            },
          }}
          hideLogColumn
          hideFooter
        />
      </Modal>

      <IconButton onClick={onClickOpenModalHandler}>
        <HistoryIcon />
      </IconButton>
    </>
  );
};

export default memo(MemberHistory);
