import {
  add,
  differenceInMinutes,
  isSameDay,
  isWithinInterval,
} from "date-fns";
import { IGroupedEvents, ITime } from "./Calendar/models";
import { EcalendarStatusCode, IAvaiableDate, ICalendar } from "./model";
import { MIN_MINUTES_HEIGHT } from "./Calendar/HourPosition";
import { TTeste } from "./Schedule";

export const getDayInfo = (day: Date, dates?: IAvaiableDate[]) => {
  const date = dates?.find((e) => isSameDay(new Date(e.calendarDate), day));

  return date;
};

export const canShowStatus = (status: EcalendarStatusCode): boolean => {
  switch (status) {
    case EcalendarStatusCode.NORMAL:
      return true;
    case EcalendarStatusCode.CONFIMED:
      return true;
    case EcalendarStatusCode.CONFIMED2:
      return true;
    case EcalendarStatusCode.EXECUTED:
      return true;
    case EcalendarStatusCode.FREE:
      return true;
    case EcalendarStatusCode.BLOCKED:
      return true;
    default:
      return false;
  }
};

export const getMonthDayInfo = (day: Date, sources: TTeste) => {
  const dates = sources
    .flatMap((s) => s.schedules)
    .filter((d) => isSameDay(new Date(d.calendarDate), day));

  const isFull =
    dates.length > 0
      ? dates.every((d) => d.calendarStatusCode === EcalendarStatusCode.NORMAL)
      : false;

  const allAvaible =
    dates.length > 0
      ? dates.every((d) => d.calendarStatusCode === EcalendarStatusCode.FREE)
      : false;

  const someAvaiable =
    dates.length > 0
      ? dates.some((d) => d.calendarStatusCode === EcalendarStatusCode.FREE)
      : false;

  return { dates, someAvaiable, isFull, allAvaible };
};

export const sortEvent = (a: ICalendar, b: ICalendar) => {
  if (a.descriptionSite < b.descriptionSite) {
    return -1;
  }
  if (a.descriptionSite > b.descriptionSite) {
    return 1;
  }

  return (
    new Date(a.calendarDate).getTime() - new Date(b.calendarDate).getTime()
  );
};

/**
 * A recursive function to group events based in their time
 *
 * @param events an array of events
 * @param groupedEvents an array of grouped events
 * @returns an array of grouped events
 */
export const groupEvents = <T extends ITime>(
  events: T[],
  groupedEvents: IGroupedEvents<T>[] = []
): IGroupedEvents<T>[] => {
  events.sort((a, b) => {
    return (
      new Date(a.calendarStartDate).getTime() -
      new Date(b.calendarStartDate).getTime()
    );
  });

  if (events.length <= 0) {
    return groupedEvents;
  }

  const [first, ...rest] = events;

  const start = new Date(first.calendarStartDate);

  let end = add(new Date(first.calendarEndDate), { minutes: -1 });

  const diference = differenceInMinutes(end, start);

  if (diference < MIN_MINUTES_HEIGHT) {
    end = add(end, { minutes: MIN_MINUTES_HEIGHT - diference });
  }

  const eventsInRage = rest.filter((event) => {
    return isWithinInterval(new Date(event.calendarStartDate), {
      start: start,
      end: end,
    });
  });

  const group = [first, ...eventsInRage];

  const sliced = rest.slice(group.length - 1);

  groupedEvents.push({
    times: group,
  });

  return groupEvents(sliced, groupedEvents);
};
