import { Box } from "@mui/material";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import { memo } from "react";
import { NoRows } from "../NoRows";
import { ISqlTableProps } from "./models";

export const SqlTable = memo(
  ({ columns, sqlReturn, isLoading }: ISqlTableProps) => {
    return (
      <Box sx={{ height: "100%" }}>
        <DataGrid
          columns={columns}
          rows={sqlReturn.map((sql, index) => ({
            ...sql,
            id: index.toString(),
          }))}
          loading={isLoading}
          sx={(theme) => ({
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor:
                theme.palette.mode === "dark"
                  ? theme.palette.grey[900]
                  : theme.palette.grey[200],
            },
          })}
          disableColumnFilter
          disableColumnMenu
          disableRowSelectionOnClick
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          slots={{
            noRowsOverlay: NoRows,
          }}
        />
      </Box>
    );
  }
);
