import { Stack, SxProps, Theme, useTheme } from "@mui/material";
import { DIMENSIONS, EBannerLocale } from "../BannersLocals/RecommendedSize";

interface IPreview {
  locales: EBannerLocale[];
  src?: string;
  sx?: SxProps<Theme>;
}

const DesktopPreview: React.FC<IPreview> = ({ locales, src, sx }) => {
  const { palette } = useTheme();

  const hasBanner = (b: EBannerLocale) => locales.includes(b);

  const getAspectRatio = (b: EBannerLocale) =>
    DIMENSIONS[b].desktop.width / DIMENSIONS[b].desktop.height;

  return (
    <Stack
      sx={sx}
      style={{
        padding: 10,
        borderRadius: 10,
        border: `10px solid ${
          palette.mode === "dark" ? palette.grey[100] : palette.grey[800]
        }`,
        aspectRatio: 16 / 9,
        gap: 10,
      }}
    >
      {hasBanner(EBannerLocale.FULL_BANNER) && (
        <img
          src={src}
          width={"100%"}
          style={{
            aspectRatio: getAspectRatio(EBannerLocale.FULL_BANNER),
            objectFit: "cover",
          }}
          alt={"Full banner example"}
        />
      )}

      <div
        style={{
          width: "50%",
          aspectRatio: 20 / 1,
          backgroundColor: palette.divider,
        }}
      ></div>

      {hasBanner(EBannerLocale.MINI_BANNER_ABOVE) && (
        <img
          src={src}
          width={"50%"}
          style={{
            aspectRatio: getAspectRatio(EBannerLocale.MINI_BANNER_ABOVE),
            objectFit: "cover",
          }}
          alt={"Mini banner example"}
        />
      )}

      <Stack style={{ width: "50%" }} direction={"row"} gap={1}>
        <div
          style={{
            width: "10%",
            backgroundColor: palette.divider,
            aspectRatio: 1 / 1,
          }}
        ></div>
        <div
          style={{
            width: "10%",
            backgroundColor: palette.divider,
            aspectRatio: 1 / 1,
          }}
        ></div>
        <div
          style={{
            width: "10%",
            backgroundColor: palette.divider,
            aspectRatio: 1 / 1,
          }}
        ></div>
      </Stack>

      {hasBanner(EBannerLocale.MINI_BANNER_BELOW) && (
        <img
          src={src}
          width={"50%"}
          style={{
            aspectRatio: getAspectRatio(EBannerLocale.MINI_BANNER_BELOW),
            objectFit: "cover",
          }}
          alt={"Mini banner example"}
        />
      )}
    </Stack>
  );
};

export default DesktopPreview;
