import { RouteObject } from "react-router-dom";
import WelcomePage from "../pages/welcomePage";
import { DomainProvider } from "@4uhub/lib4uhub";
import WorkGroupsPage from "../pages/4uHcp/PreventMedicine/WorkGroups/WorkGroupsPage";
import WorkGroup from "../pages/4uHcp/PreventMedicine/WorkGroups/WorkGroupForm/WorkGroup";
import HealthProgramsPage from "../pages/4uHcp/PreventMedicine/HealthPrograms/HealthProgramsPage";
import HealthProgram from "../pages/4uHcp/PreventMedicine/HealthPrograms/HealthProgramForm/HealthProgram";
import HealthProgramsContentRulesPage from "../pages/4uHcp/PreventMedicine/HealthProgramsContentRules/HealthProgramsContentRules";
import HealthProgramContentRule from "../pages/4uHcp/PreventMedicine/HealthProgramsContentRules/HealthProgramContentRule/HealthProgramContentRule";
import RulesContentsPage from "../pages/4uHcp/PreventMedicine/RulesContents/RulesContentsPage";
import RuleContent from "../pages/4uHcp/PreventMedicine/RulesContents/RuleContentForm/RuleContent";
import FundraisingPage from "../pages/4uHcp/PreventMedicine/HealthPrograms/Fundraising/FundraisingPage/FundraisingPage";
import Captation from "../pages/4uHcp/PreventMedicine/HealthPrograms/Fundraising/Captation";
import HealthProgramsImportsPage from "../pages/4uHcp/PreventMedicine/HealthPrograms/Imports/HealthProgramsImportsPage";
import HealthProgramImport from "../pages/4uHcp/PreventMedicine/HealthPrograms/Imports/HealthProgramImport";
import MembersPage from "../pages/4uHcp/PreventMedicine/HealthPrograms/RowActions/Participants/MembersPage";

const hcp: RouteObject[] = [
  {
    index: true,
    element: <WelcomePage />,
  },
  {
    path: "structure/workGroups",
    element: (
      <DomainProvider value={{ domainName: "4ubususers" }}>
        <WorkGroupsPage />
      </DomainProvider>
    ),
  },
  {
    path: "structure/workGroups/new",
    element: (
      <DomainProvider value={{ domainName: "4ubususers" }}>
        <WorkGroup />
      </DomainProvider>
    ),
  },
  {
    path: "structure/workGroups/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "4ubususers" }}>
        <WorkGroup />
      </DomainProvider>
    ),
  },
  {
    path: "structure/healthPrograms",
    element: (
      <DomainProvider value={{ domainName: "healthprogram" }}>
        <HealthProgramsPage />
      </DomainProvider>
    ),
  },
  {
    path: "structure/healthPrograms/new",
    element: (
      <DomainProvider
        value={{ domainName: "healthprogram" }}
        actions={["Create"]}
      >
        <HealthProgram />
      </DomainProvider>
    ),
  },
  {
    path: "structure/healthPrograms/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "healthprogram" }}>
        <HealthProgram />
      </DomainProvider>
    ),
  },
  {
    path: "structure/healthPrograms/:healthProgramId/participants",
    element: (
      <DomainProvider value={{ domainName: "healthprogram" }}>
        <MembersPage />
      </DomainProvider>
    ),
  },
  {
    path: "captation/imports/:healthProgramId?",
    element: (
      <DomainProvider value={{ domainName: "healthprogram" }}>
        <HealthProgramsImportsPage />
      </DomainProvider>
    ),
  },
  {
    path: "captation/imports/:healthProgramId?/new",
    element: (
      <DomainProvider value={{ domainName: "healthprogram" }}>
        <HealthProgramImport />
      </DomainProvider>
    ),
  },
  {
    path: "captation/captations/:healthProgramId?",
    element: (
      <DomainProvider value={{ domainName: "healthprogramcaptation" }}>
        <FundraisingPage />
      </DomainProvider>
    ),
  },
  {
    path: "captation/captations/:healthProgramId?/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "healthprogramcaptation" }}>
        <Captation />
      </DomainProvider>
    ),
  },
  {
    path: "content/contentRules",
    element: (
      <DomainProvider value={{ domainName: "healthprogram" }}>
        <HealthProgramsContentRulesPage />
      </DomainProvider>
    ),
  },
  {
    path: "content/contentRules/new",
    element: (
      <DomainProvider
        value={{ domainName: "healthprogram" }}
        actions={["Create"]}
      >
        <HealthProgramContentRule />
      </DomainProvider>
    ),
  },
  {
    path: "content/contentRules/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "healthprogram" }}
        actions={["Read"]}
      >
        <HealthProgramContentRule />
      </DomainProvider>
    ),
  },
  {
    path: "content/contentRules/:ruleId/contents",
    element: (
      <DomainProvider value={{ domainName: "healthprogram" }}>
        <RulesContentsPage />
      </DomainProvider>
    ),
  },
  {
    path: "content/contentRules/:ruleId/contents/new",
    element: (
      <DomainProvider
        value={{ domainName: "healthprogram" }}
        actions={["Create"]}
      >
        <RuleContent />
      </DomainProvider>
    ),
  },
  {
    path: "content/contentRules/:ruleId/contents/:id/edit",
    element: (
      <DomainProvider
        value={{ domainName: "healthprogram" }}
        actions={["Update"]}
      >
        <RuleContent />
      </DomainProvider>
    ),
  },
];

export default hcp;
