export const CNJP_VALIDATOR = /^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}$/;
export const CEP_VALIDATOR = /\d{5}-\d{3}/;
export const CEL_TEL_VALIDATOR =
  /^(?:(?:\+|00)?(55)\s?)?(?:(?:\(?[1-9][0-9]\)?)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/;
export const CPF_VALIDATOR = /^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/;
export const VERSION_VALIDATOR = /^[0-9.]+$/;
export const EMAIL_VALIDATOR =
  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const SITE_VALIDATOR = /^www\.[a-zA-Z0-9-]+\.(com\.br|com|net|br|org)$/;
export const CURLY_BRACKETS_VALIDATOR = /{{[\wÀ-ÿ]+}}/g;
export const HTML_TAGS_VALIDATOR = /<[^>]*>/g;
