import { useTranslation } from "react-i18next";
import LogTasyIPM from "./LogTasyIpm";

const TASY_X_IPM = process.env.REACT_APP_4UBUS + "/api/v1/ForuBusLogTasyIpm";

const TasyIpm = () => {
  const {t} = useTranslation();

  return <LogTasyIPM route={TASY_X_IPM} title={t("components.menu.4ubus.logs.tasyipm")} />;
};

export default TasyIpm