import { Stack } from "@mui/material";
import {
  DateCalendar,
  DayCalendarSkeleton,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { IAvaiableDate } from "../../../../../model";
import useDate from "../../../../../../../hooks/useDate";
import CalendarDay from "../../../../../Calendar/CalendarDay";
import Legend from "./Legend";

interface IScheduleCalendarProps {
  datesLoading: boolean;
  date?: Date | null;
  onDateChange: (d: Date | null) => void;
  onMonthChange: (month: Date) => void;
  dates: IAvaiableDate[];
  defaultValue?: Date;
  fullDayColor?: string;
  someAvaiableColor?: string;
  allAvaiableColor?: string;
}

const ScheduleCalendar: React.FC<IScheduleCalendarProps> = ({
  datesLoading,
  date,
  onDateChange,
  onMonthChange,
  dates,
  defaultValue,
  allAvaiableColor,
  fullDayColor,
  someAvaiableColor,
}) => {
  const { t } = useTranslation();
  const { locale } = useDate();

  return (
    <Stack>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <DateCalendar
          disablePast
          referenceDate={defaultValue}
          loading={datesLoading}
          value={date}
          onChange={onDateChange}
          onMonthChange={onMonthChange}
          renderLoading={() => <DayCalendarSkeleton />}
          slots={{
            day: CalendarDay,
          }}
          slotProps={{
            day: {
              avaiableDates: dates,
              fullDayColor,
              someAvaiableColor,
              allAvaiableColor,
              disableNotAvaiableDates: true,
            } as any,
          }}
        />
      </LocalizationProvider>
      <Stack>
        <Legend
          label={t("components.schedule.menu.subtitle.someFree")}
          color={someAvaiableColor}
        />
        <Legend
          label={t("components.schedule.menu.subtitle.allFree")}
          color={allAvaiableColor}
        />
      </Stack>
    </Stack>
  );
};

export default memo(ScheduleCalendar);
