import { IconButton, Stack, Tooltip } from "@mui/material";
import { IFiltersActionsProps } from "./models";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { useTranslation } from "react-i18next";

import CachedIcon from "@mui/icons-material/Cached";

const translationPath = "components.filtersActions.";

const FiltersActions = ({
  disableFilterBtn,
  showCleanBtn,
  onFilter,
  onClean,
  onRefresh,
}: IFiltersActionsProps) => {
  const { t } = useTranslation();

  return (
    <Stack flexDirection={"row"}>
      {(onRefresh || onFilter) && showCleanBtn !== undefined && (
        <Tooltip title={t(translationPath + "refresh")}>
          <IconButton
            onClick={onRefresh}
          >
            <CachedIcon />
          </IconButton>
        </Tooltip>
      )}
      {onFilter && (
        <Tooltip title={t(translationPath + "filter")}>
          <div>
            <IconButton onClick={onFilter} disabled={disableFilterBtn}>
              <FilterAltIcon />
            </IconButton>
          </div>
        </Tooltip>
      )}
      {showCleanBtn && onClean && (
        <Tooltip title={t(translationPath + "clean")}>
          <IconButton onClick={onClean}>
            <FilterAltOffIcon />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export default FiltersActions;
