import { Stack, Typography } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { formatRelative } from "date-fns";

import useDate from "../../../../../hooks/useDate";

interface IConfirmDateProps {
  startHour: Date;
  duration: number;
}

const ConfirmDate: React.FC<IConfirmDateProps> = ({ duration, startHour }) => {
  const { t } = useTranslation();

  const { locale, localeFormat } = useDate();

  return (
    <Stack
      gap={1}
      padding={2}
      sx={(t) => ({
        borderRadius: t.shape.borderRadius / 10,
        backgroundColor: t.palette.primary.main,
        color: "white",
      })}
    >
      <Stack gap={1} direction={"row"} alignItems={"center"}>
        <EventIcon />
        <Typography fontWeight={"bold"}>
          {t("components.scheduleAssistant.newSchedule.confirm.schedule")}
        </Typography>
      </Stack>
      <Typography>{localeFormat(startHour, "Pp")}</Typography>
      <Typography>{duration} min</Typography>
      <Typography variant="body2">
        {formatRelative(startHour, new Date(), { locale })}
      </Typography>
    </Stack>
  );
};

export default memo(ConfirmDate);
