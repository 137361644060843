import {
  AutoCompleteUncontrolled,
  ISelectType,
  ModalFilters,
  useFetch,
} from "@4uhub/lib4uhub";
import {
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import MainApi from "../../../services/mainApi.service";
import { SelectRelationshipType } from "./SelectRelationshipType/SelectRelationshipType";

const GENERIC_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";

const genericService = new MainApi<ISelectType>(GENERIC_ROUTE);

interface IRelationShipFormProps {
  filterParams: Record<string, any> | undefined;
  setFilterParams: (params?: Record<string, any>) => void;
}

const translationPath = "page.4uBus.relationships.";

const RelationShipFilter: React.FC<IRelationShipFormProps> = ({
  filterParams,
  setFilterParams,
}) => {
  const [relationshipType, setRelationshipType] = useState<ISelectType | null>(
    null
  );

  const [directionType, setDirectionType] = useState<ISelectType | null>(null);

  const [directionTypeOptions, setDirectionTypeOptions] = useState<
    ISelectType[]
  >([]);

  const { t } = useTranslation();

  const { sendRequest } = useFetch(genericService.list);

  const fetchDirectionTypeOptions = useCallback(async () => {
    const { data, success } = await sendRequest({
      Identifier: "DIRECTION_TYPE",
    });
    if (data && success) {
      setDirectionTypeOptions(data);
    }
  }, [sendRequest]);

  const filterHandler = () => {
    setFilterParams({
      RelationshipTypeCode: relationshipType?.code,
      DirectionTypeCode: directionType?.code,
    });
  };

  const cleanFilterHandler = () => {
    setDirectionType(null);
    setRelationshipType(null);
    setFilterParams(undefined);
  };

  useEffect(() => {
    fetchDirectionTypeOptions();
  }, [fetchDirectionTypeOptions]);

  const badgeCount = filterParams
    ? Object.keys(filterParams).filter((key) => filterParams[key] !== undefined)
        .length
    : 0;

  return (
    <ModalFilters
      badgeCount={badgeCount}
      actionsDisabled={!relationshipType && !directionType}
      onFilter={filterHandler}
      onCleanFilter={cleanFilterHandler}
    >
      <>
        <SelectRelationshipType
          relationshipType={relationshipType}
          setRelationshipType={setRelationshipType}
        />
        <AutoCompleteUncontrolled
          label={t(translationPath + "direction_type")}
          size="small"
          value={directionType}
          onValueSelected={setDirectionType}
          options={directionTypeOptions}
        />
      </>
    </ModalFilters>
  );
};

export default memo(RelationShipFilter);
