import { AutoCompleteMulti, ISelectType } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import MainApiService from "../../../../../../services/mainApi.service";
import RecommendedSize from "./RecommendedSize";
const BANNER_LOCALS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";

const translationPath = "page.register.banners.";

const bannerLocalsService = new MainApiService<ISelectType>(
  BANNER_LOCALS_ROUTE
);

const BannersLocals = () => {
  const { t } = useTranslation();
  return (
    <Grid item xs={12} sm={12}>
      <AutoCompleteMulti
        params={{ Identifier: "BANNER_LOCALS" }}
        size="small"
        label={t(translationPath + "banner_locals")}
        name="bannerLocals"
        request={bannerLocalsService.list}
        helperText={<RecommendedSize />}
      />
    </Grid>
  );
};

export default memo(BannersLocals);
