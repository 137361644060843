import { memo, useState } from "react";
import { Button, Modal } from "@4uhub/lib4uhub";
import { Box, IconButton } from "@mui/material";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "react-i18next";

import PrettyJson from "../../admSystem/establishments/OnPremise/PrettyJson";
import useCopy from "../../../hooks/useCopy";

interface IErrorMessageProps {
  children: string;
}

const translationPath = "page.evaluation.logs.";

const ErrorMessage: React.FC<IErrorMessageProps> = ({ children }) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const { copy } = useCopy();

  const onOpenHandler = () => {
    setOpen(true);
  };

  const onCloseHandler = () => {
    setOpen(false);
  };

  const onCopyHandler = async () => {
    await copy(children);
    onCloseHandler();
  };

  return (
    <>
      <IconButton onClick={onOpenHandler}>
        <AnnouncementIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={onCloseHandler}
        title={t(translationPath + "errorMessage") || "Error Message"}
      >
        <Box width={"100%"} overflow={"auto"}>
          <PrettyJson>{children}</PrettyJson>
        </Box>

        <Button
          startIcon={<ContentCopyIcon />}
          variant="contained"
          onClick={onCopyHandler}
        >
         {t(translationPath + "copy") || "Copy"}
        </Button>
      </Modal>
    </>
  );
};

export default memo(ErrorMessage);
