import {
  Button,
  Modal,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import { Link, Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { disapproveHealthProgramCaptaion } from "../../../../../services/healthProgramCaptation.service";
import { useNavigate } from "react-router-dom";

const translationPath = "page.4uHcp.hcp.fundraising.";

interface IDisapproveCaptationProps {
  healthProgramCaptationId: string;
  redirect?: boolean;
  type?: "button" | "link";
  onRefresh?: () => void;
}

const DisapproveCaptation = ({
  healthProgramCaptationId,
  redirect = false,
  type = "link",
  onRefresh,
}: IDisapproveCaptationProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { setMessage } = useNotificationContext();

  const [denialReason, setDenialReason] = useState("");

  const [open, setOpen] = useState(false);

  const openHandler = () => setOpen(true);
  const closeHandler = () => setOpen(false);

  const { sendRequest, loading } = useFetch(disapproveHealthProgramCaptaion);

  const disapproveCaptation = useCallback(async () => {
    const { data, success } = await sendRequest({
      healthProgramCaptationsId: healthProgramCaptationId,
      denialReason: denialReason.length !== 0 ? denialReason : null,
    });
    if (data && success) {
      setMessage({ message: t(translationPath + "disapproved_successfully") });
      closeHandler();
      if (redirect) {
        navigate(-1);
      }
      if (onRefresh) {
        onRefresh();
      }
    }
  }, [
    onRefresh,
    setMessage,
    sendRequest,
    navigate,
    redirect,
    t,
    denialReason,
    healthProgramCaptationId,
  ]);

  return (
    <>
      <Modal
        title={`${t(translationPath + "disapprove_captation")}`}
        open={open}
        onClose={closeHandler}
      >
        <Stack pt={2} flexDirection={"column"} gap={2}>
          <TextField
            size="small"
            label={t(translationPath + "denial_reason")}
            value={denialReason}
            onChange={(e) => setDenialReason(e.target.value)}
            multiline
            minRows={2}
          />
          <Stack flexDirection={"row"} gap={2}>
            <Button
              variant="outlined"
              size="small"
              fullWidth
              onClick={closeHandler}
            >
              {t(translationPath + "cancel")}
            </Button>
            <Button
              variant="contained"
              size="small"
              fullWidth
              loading={loading}
              onClick={disapproveCaptation}
            >
              {t(translationPath + "disapprove")}
            </Button>
          </Stack>
        </Stack>
      </Modal>

      {type === "button" ? (
        <Button variant="outlined" size="small" onClick={openHandler}>
          {t(translationPath + "disapprove_captation")}
        </Button>
      ) : (
        <Link
          sx={{ cursor: "pointer" }}
          onClick={(event) => {
            event.stopPropagation();
            openHandler();
          }}
        >
          {t(translationPath + "disapprove_captation")}
        </Link>
      )}
    </>
  );
};

export default DisapproveCaptation;
