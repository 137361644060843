import { GridColDef } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Table } from "@4uhub/lib4uhub";

import MainApi from "../../../../../services/mainApi.service";
import { useCallback, useMemo } from "react";
import { IHealthProgramImport } from "../../../../../models/health-programs-imports";
import { Chip } from "@mui/material";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import useDownload from "../../../../../hooks/useDownload";

const HEALTH_PROGRAM_IMPORTS_ROUTE =
  process.env.REACT_APP_MAIN +
  "/api/v1/HealthProgramImports/GetAllImportsWithPagination/";
const healthProgramImportsService = new MainApi<IHealthProgramImport>(
  HEALTH_PROGRAM_IMPORTS_ROUTE
);

const DOWNLOAD_TEMPLATE_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgramImports/GetCsvTemplate";

const translationPath = "page.4uHcp.hcp.healthProgramImports.";

const HealthProgramsImportsPage = () => {
  const navigate = useNavigate();

  const { downloadFileParams, loading } = useDownload();

  const { healthProgramId } = useParams();

  const { t } = useTranslation();

  const columns: GridColDef<IHealthProgramImport>[] = [
    {
      field: "healthProgram",
      headerName: t(translationPath + "healthProgram") || "Nome do arquivo",
      renderCell: (params) => {
        return params.value?.name ?? "-";
      },
    },
    {
      field: "fileName",
      headerName: t(translationPath + "file_name") || "Nome do arquivo",
    },
    {
      field: "importPersonName",
      headerName: t(translationPath + "import_person_name") || "Imported by",
    },
    {
      field: "errorMessage",
      headerName: t(translationPath + "error_message") || "Error message",
      renderCell: (params) => params.value ?? "-",
    },
    {
      field: "healthProgramImportStatus",
      headerName: t(translationPath + "status") || "Status",
      maxWidth: 200,
      renderCell: (params) => {
        if (!params.value) return "-";

        const statusCode = params.value.code;
        return (
          <Chip
            size="small"
            label={params.value.name}
            color={
              statusCode === "1"
                ? "primary"
                : statusCode === "2"
                ? "success"
                : statusCode === "3"
                ? "error"
                : "default"
            }
          />
        );
      },
    },
  ];

  const props = useMemo(() => {
    return {
      HealthProgramId: healthProgramId,
    };
  }, [healthProgramId]);

  const onAdd = () => {
    navigate("new");
  };

  const downloadTemplateHandler = useCallback(() => {
    downloadFileParams(DOWNLOAD_TEMPLATE_ROUTE, {}, "template.csv");
  }, [downloadFileParams]);

  return (
    <Table<IHealthProgramImport>
      showDefaultMacroFilters={false}
      service={healthProgramImportsService}
      columns={columns}
      title={t(translationPath + "imports")}
      searchInput
      defaultActions={false}
      showDelete={false}
      showDeleteButton={false}
      defaultPageSize={20}
      backButton
      onAdd={onAdd}
      serviceProps={props}
      pageSizeOptions={[5, 10, 20]}
      macroFilters={() => (
        <Button
          variant="contained"
          loading={loading}
          startIcon={!loading ? <FileDownloadIcon /> : undefined}
          onClick={downloadTemplateHandler}
        >
          {t(translationPath + "download_template")}
        </Button>
      )}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for an import"
      }
      addButtonLabel={t(translationPath + "add_import") || "Add Import"}
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default HealthProgramsImportsPage;
