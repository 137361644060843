import { Grid, Stack, Typography } from "@mui/material";

import { EBannerLocale } from "../BannersLocals/RecommendedSize";
import DesktopPreview from "./DesktopPreview";
import MobilePreview from "./MobilePreview";

interface IImagePreview {
  src?: string;
  locales: EBannerLocale[];
  showDesktop: boolean;
  showMobile: boolean;
}

const ImagePreview: React.FC<IImagePreview> = ({
  locales,
  src,
  showDesktop,
  showMobile,
}) => {
  return (
    <Stack p={1} sx={{ width: "100%" }}>
      <Typography variant="h6" mb={1}>
        Preview
      </Typography>
      <Grid container spacing={2}>
        {showDesktop && (
          <Grid item xs={12} md={8} lg={4}>
            <DesktopPreview
              locales={locales}
              src={src}
              sx={{ width: "100%" }}
            />
          </Grid>
        )}
        {showMobile && (
          <Grid item={true} xs={5} sm={3} md={2} lg={1.2}>
            <MobilePreview locales={locales} src={src} sx={{ width: "100%" }} />
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};

export default ImagePreview;
