import { z } from "zod";

const translationPath = "page.4uBus.relationships.errors.";

const setRelationshipTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "relationship_type" }
);

const directionTypeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "direction_type" }
);

export const relationShipSchema = z.object({
  id: z.string().optional(),
  enable: z.boolean(),
  default: z.boolean(),
  setRelationshipType: setRelationshipTypeSchema,
  directionType: directionTypeSchema,
  originName: z.string().optional(),
  originValue: z.string().optional(),
  destinyName: z.string().optional(),
  destinyValue: z.string().optional(),
});

export type TRelationShipForm= z.infer<typeof relationShipSchema>;
